"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HelperUtils_1 = require("./HelperUtils");
String.prototype.h5locale = function () {
    const appType = localStorage.getItem('appType');
    const parsedAppType = appType === 'null' ? null : JSON.parse(appType);
    return parsedAppType === 'culture'
        ? (0, HelperUtils_1.getCultureLocale)(String(this))
        : (0, HelperUtils_1.getObjectiveLocale)(String(this));
};
String.prototype.replaceLocale = function (replacements) {
    let res = String(this);
    for (const placeholder in replacements) {
        res = res.replace(`[${placeholder}]`, replacements[placeholder]);
    }
    return res;
};
String.prototype.replaceElementLocale = function (replaceTargetObj) {
    let localedText = String(this);
    const SEPARATOR = '~|~';
    replaceTargetObj.forEach((target) => {
        if (!target.identifier || !target.replacementElement)
            return;
        const indexPos = localedText.indexOf(target.identifier);
        if (localedText.indexOf(target.identifier) > -1) {
            const textarr = localedText.split('');
            textarr.splice(indexPos + target.identifier.length, 0, SEPARATOR);
            textarr.splice(indexPos, 0, SEPARATOR);
            localedText = textarr.join('');
        }
    });
    return localedText.split(SEPARATOR).map((element) => {
        const targetIndex = replaceTargetObj.findIndex((target) => target?.identifier == element);
        if (targetIndex < 0)
            return element;
        return replaceTargetObj[targetIndex].replacementElement;
    });
};

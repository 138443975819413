import client from './ApiClient';

function getUser(token) {
  let headers = {};
  if (token) {
    headers = { Authorization: 'Bearer ' + token };
  }
  return client('v1/auth', {
    headers: headers
  });
}

function getCustomUser() {
  return client('v1/custom_auth');
}

function testOrganization(organization) {
  return client(`v1/auth/${organization}`);
}

function loginUserWithToken(body, organization) {
  return client(`/v1/auth/${organization}/web/submit_device_otp`, {
    method: 'POST',
    body: body
  });
}

function loginUser(body, captcha, headers = {}) {
  const { organization, ...rest } = body;
  return client(`v1/auth/${organization}/web`, {
    method: 'POST',
    body: { ...rest, captcha },
    headers
  });
}

function submitOrganization(email, organization, captcha) {
  const params = {
    email: email,
    teamName: organization
  };
  if (captcha) params['captcha'] = captcha;

  return client(`/culture/password/check_user_status`, {
    method: 'GET',
    params: params
  });
}

function submitEmail(email, captcha) {
  const params = {
    email: email
  };
  if (captcha) params['captcha'] = captcha;

  return client(`/culture/password/check_user_status`, {
    method: 'GET',
    params: params
  });
}

function forgotPassword(organization, email) {
  return client(`v1/passwords`, {
    method: 'POST',
    body: { identifier: organization, email }
  });
}

function resetPassword(token, password) {
  return client(`v1/password_resets/${token}`, {
    method: 'PUT',
    body: { password }
  });
}

function getResetPassword(token) {
  return client(`v1/password_resets/${token}`, { method: 'GET' });
}

function activationUser(token, password) {
  return client(`v1/activations/${token}`, {
    method: 'PUT',
    body: { password }
  });
}

function getActivationUser(token) {
  return client(`v1/activations/${token}`, { method: 'GET' });
}

function getOrganizationOnly(teamName) {
  return client(`culture/organizations/${teamName}`, { method: 'GET' });
}

function logoutUser() {
  return client(`v1/auth`, { method: 'DELETE', body: {} });
}

function submitRegistration(body) {
  return client(`culture/password/verify_registration`, {
    method: 'PATCH',
    body: body
  });
}

function sendActivation(body) {
  return client(`v1/send_activation`, { method: 'POST', body: body });
}

function resendOTP(identifier, body) {
  return client(`v1/auth/${identifier}/web/request_device_otp`, {
    method: 'POST',
    body
  });
}

export {
  loginUser,
  testOrganization,
  getUser,
  forgotPassword,
  resetPassword,
  activationUser,
  getResetPassword,
  getActivationUser,
  getCustomUser,
  submitEmail,
  submitOrganization,
  loginUserWithToken,
  getOrganizationOnly,
  logoutUser,
  submitRegistration,
  sendActivation,
  resendOTP
};

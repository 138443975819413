// Uncomment this if you want to debug why component is re-rendering || https://www.npmjs.com/package/@welldone-software/why-did-you-render
// import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import client from 'client/FetchClient';
import AppProviders from 'context';
import { DownloaderProvider } from 'context/DownloaderContext';
import { RefetchQueryProvider } from 'context/RefetchQueryContext';
import 'utils/StringUtils';

import * as serviceWorker from '../public/service-worker.js';
import App from './app';

const defaultQueryFn = async (key, params, noConvertCase = false) => {
  const data = await client(key.queryKey[0], params, noConvertCase);
  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

// optional mock service
const localStorageEnv = JSON.parse(localStorage.getItem('env') || '{}');
if (localStorageEnv.USE_MOCK) {
  import('./mock');
}

const rootEl = document.getElementById('wrapper');
const element = document.getElementById('splash-wrapper');
if (document.readyState !== 'loading' && element) {
  element.remove();
}

const root = createRoot(rootEl);

root.render(
  <QueryClientProvider client={queryClient}>
    <RefetchQueryProvider>
      <AppProviders>
        <DownloaderProvider>
          <App />
        </DownloaderProvider>
      </AppProviders>
    </RefetchQueryProvider>
  </QueryClientProvider>,
  rootEl
);

serviceWorker.register();

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import qs from 'qs';

import { getENV } from 'utils/HelperUtils';

import { toCamelCase, toSnakeCase } from '../utils/caseConverter';
import { Axios } from './Axios';
import { buildErrorData, handleSentry } from './helperClient';

// set default timeout for axios
Axios.defaults.timeout = 60000;

function client(
  endpoint,
  {
    body,
    method,
    params,
    headers = {},
    shouldShowErrorToast = true,
    whitelistCode = [],
    skipNullParams = true
  } = {},
  noConvertCase,
  signal
) {
  const authorizationMethod = getENV('AUTHORIZATION_METHOD');
  const token = localStorage.getItem('bearer');
  const shouldSendToken = !(endpoint.includes('v1/auth/') && body?.password);
  const OTP_PASS_TOKEN = getENV('OTP_PASS_TOKEN');

  const newHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(authorizationMethod === 'bearer' &&
      token &&
      shouldSendToken && { Authorization: 'Bearer ' + token }),
    ...(OTP_PASS_TOKEN && { 'OTP-Pass-Token': OTP_PASS_TOKEN }),
    ...headers
  };

  const url = getENV('PERFORMANCE_API_HOST');

  const config = {
    url: `${url}/${endpoint}`,
    signal: signal, // for abort request, ref: https://axios-http.com/docs/cancellation
    headers: {
      ...newHeaders
    }
  };

  if (authorizationMethod === 'cookie' && shouldSendToken) {
    Axios.defaults.withCredentials = true;
  }

  if (!isEmpty(params)) {
    if (!params.q) {
      delete params.q;
    }

    params = !noConvertCase ? toSnakeCase(params) : params;
    const convertedParams = qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true,
      skipNulls: skipNullParams
    });

    if (convertedParams) {
      config.url = config.url + '?' + convertedParams;
    }
  }

  if (body) {
    config.data = !noConvertCase ? toSnakeCase(body) : body;
    if (!method) {
      (() => {
        throw 'method must be present';
      })();
    }
  }

  if (method) {
    config.method = method;
  }

  function NetworkError(message) {
    this.name = 'NetworkError';
    this.message = message;
    this.stack = new Error().stack;
  }
  NetworkError.prototype = Object.create(Error.prototype);
  NetworkError.prototype.constructor = NetworkError;

  const onSuccess = (r) => {
    let data = r.data.data != null ? r.data.data : r.data.properties;
    let pagination, metadata, analytics, totalObjectives, totalWeights;

    if (!noConvertCase) {
      data = toCamelCase(data);
      pagination = toCamelCase(r.data.pagination);
      metadata = toCamelCase(omit(r.data, ['data', 'pagination']));
      analytics = toCamelCase(r.data.analytics);
      totalObjectives = toCamelCase(r.data.total_objectives);
      totalWeights = toCamelCase(r.data.total_weights);
    } else {
      pagination = r.data.pagination;
      metadata = omit(r.data, ['data', 'pagination']);
      analytics = r.data.analytics;
      totalObjectives = r.data.total_objectives;
      totalWeights = r.data.total_weights;
    }

    return {
      isSuccess: true,
      error: null,
      status: r?.status,
      ...r.data,
      data,
      metadata,
      pagination,
      analytics,
      totalObjectives,
      totalWeights
    };
  };

  const onError = (e) => {
    // get error data from backend
    const error = buildErrorData(e);

    const isPassWhitelist = whitelistCode.includes(error?.code);

    if (shouldShowErrorToast && e?.showErrorToast && !isPassWhitelist) {
      e?.showErrorToast();
    }

    const sentError =
      process.env.NODE_ENV === 'production' &&
      error?.code &&
      error?.code !== 401 &&
      error?.code !== 422 &&
      !isPassWhitelist;

    if (sentError && !error?.message.includes('Goal/Task not found')) {
      handleSentry({
        error: error,
        endpoint: endpoint,
        config: config,
        method: e.response?.config?.method,
        raw: e
      });
    }

    return { isSuccess: false, data: null, pagination: null, error: error };
  };

  return Axios(config).then(onSuccess).catch(onError);
}

export default client;

import client from './ApiClient';

//=========================== SWITCH POSITION ==============================

function getListSwitchPosition(userid, query) {
  return client(`v1/users/${userid}/positions`, { params: query });
}

function updateSwitchPosition(userid, placementid) {
  return client(`v1/users/${userid}/position/switch/${placementid}`, {
    method: 'PATCH',
    body: {}
  });
}

//=========================== REVIEW TEMPLATE ==============================

function getListTemplate(query) {
  return client(`/v1/admin/review_templates`, { params: query });
}

function postTemplate(data) {
  return client(`v1/admin/review_templates`, { method: 'POST', body: data });
}

function getTemplateById(id) {
  return client(`v1/admin/review_templates/${id}`);
}

function editTemplateById(id, data) {
  return client(`v1/admin/review_templates/${id}`, {
    method: 'PUT',
    body: data
  });
}

function deleteTemplateById(id) {
  return client(`v1/admin/review_templates/${id}`, {
    method: 'DELETE',
    body: {}
  });
}

//=========================== PERMISSIONS =====================================

function updateStateGroupsPermission(id, body) {
  return client(`admin/groups/${id}/change_state`, {
    method: 'PATCH',
    body: body
  });
}

function postGroupsPermission(body) {
  return client(`admin/groups`, {
    method: 'POST',
    body: body
  });
}

function updateGroupsPermission(id, body, noConvertCase) {
  return client(
    `admin/groups/${id}`,
    {
      method: 'PUT',
      body: body
    },
    noConvertCase
  );
}

function getUserPlacementPermission(
  userId,
  placementId,
  params,
  noConvertCase
) {
  return client(
    `v1/users/${userId}/placements/${placementId}/permissions`,
    {
      params: params
    },
    noConvertCase
  );
}

function patchUserPlacementPermission(
  userId,
  placementId,
  body,
  noConvertCase
) {
  return client(
    `v1/users/${userId}/placements/${placementId}/permissions`,
    {
      method: 'PATCH',
      body: body
    },
    noConvertCase
  );
}

function getListGroupsPermission(params) {
  return client(`admin/groups`, {
    params: params
  });
}

function getGroupById(id, params, noConvertCase) {
  return client(
    `admin/groups/${id}`,
    {
      params: params
    },
    noConvertCase
  );
}

function getPlacementInfoKey(params) {
  return client(`v1/placement_info_keys`, {
    params: params
  });
}

function getPlacementInfo(type, params) {
  return client(`v1/placement_infos/${type}`, {
    params: params
  });
}

//=========================== REVIEW GROUP =====================================

function getListUser(params) {
  return client(`v1/admin/users`, { params: params });
}

function getListFilter(params, signal) {
  return client(`v1/users/filters`, { params: params }, false, signal);
}

//=========================== MANAGE USER =====================================

function getUserInfo(userId) {
  return client(`v1/users/${userId}`);
}

function importUsers(body) {
  return client('v1/admin/users/imports', { method: 'POST', body });
}

function syncUser(params) {
  return client('admin/users/sync', { method: 'POST', params });
}

function syncSlack() {
  return client('integrations/slack/sync_users', { method: 'POST' });
}

function installSlack(body) {
  return client('integrations/slack/install', { method: 'POST', body: body });
}

//=========================== CMS CULTURE TEAMS =====================================
function getTeamsCMSCulture(params) {
  return client(`culture/cms/teams`, { params: params });
}

function postTeamsCMSCulture(data) {
  return client(`culture/cms/teams`, { method: 'POST', body: data });
}

function getTeamsIdCMSCulture(id) {
  return client(`culture/cms/teams/${id}`);
}

function patchTeamsIdCMSCulture(id, data) {
  return client(`culture/cms/teams/${id}`, { method: 'PATCH', body: data });
}

function deleteTeamsIdCMSCulture(id) {
  return client(`culture/cms/teams/${id}`, { method: 'DELETE', body: id });
}

function postTeamsAddMemberCMSCulture(data) {
  return client(`culture/cms/teams/add_member`, {
    method: 'POST',
    body: data
  });
}

function deleteTeamsRemoveMemberCMSCulture(id, data) {
  return client(`culture/cms/teams/${id}/remove_member`, {
    method: 'DELETE',
    body: data
  });
}
//=========================== CMS CULTURE Organization ===================================
function getOrganization() {
  return client(`/root/organizations`);
}

function addOrganization(data) {
  return client(`v2/root/organizations`, {
    method: 'POST',
    body: data
  });
}

function getOrganizationDetail(orgId, params) {
  return client(`/root/organizations/${orgId}`, { params: params });
}

function updateOrganization(orgId, data) {
  return client(`/root/organizations/${orgId}`, { method: 'PUT', body: data });
}

function deleteOrganization(id, data = {}) {
  return client(`/root/organizations/${id}`, { method: 'DELETE', body: data });
}

//=========================== CMS CULTURE Leaderboard =====================================
function getLeaderboardsCMSCulture(type) {
  return client(`culture/cms/leaderboards/${type}`);
}

function getLeaderboardsUsersCMSCulture(type, query) {
  return client(`culture/cms/leaderboards/users/${type}`, {
    params: query
  });
}

function getLeaderboardsPostsCMSCulture(type, filterListType, query) {
  return client(`/culture/cms/leaderboards/${type}/${filterListType}`, {
    params: query
  });
}

//=========================== CMS CULTURE Label =====================================
function getLabelCMSCulture(query) {
  return client(`culture/cms/labels`, { params: query });
}

function postLabelCMSCulture(data) {
  return client(`culture/cms/labels`, { method: 'POST', body: data });
}

function updateLabelCMSCulture(id, data) {
  return client(`culture/cms/labels/${id}`, { method: 'PATCH', body: data });
}

function deleteLabelCMSCulture(id) {
  return client(`culture/cms/labels/${id}`, { method: 'DELETE', body: {} });
}

//=========================== CMS CULTURE values =====================================
function getPreSignUrlValues(query) {
  return client(`/culture/cms/uploads/aws_sign`, { params: query });
}

function getValues(query) {
  return client(`/culture/cms/values`, { params: query });
}

function addValues(data) {
  return client(`/culture/cms/values`, { method: 'POST', body: data });
}

function updateValues(id, data) {
  return client(`/culture/cms/values/${id}`, { method: 'PATCH', body: data });
}

function deleteValuesCMSCulture(id, data) {
  return client(`culture/cms/values/${id}`, { method: 'DELETE', body: data });
}

//=========================== CMS CULTURE groups =====================================
function getGroupCMSCulture(query) {
  return client(`culture/cms/groups`, { params: query });
}

function getGroupIdCMSCulture(id) {
  return client(`culture/cms/groups/${id}`);
}

//=========================== CMS CULTURE surveys =====================================
function getSurveyCMSCulture(query) {
  return client(`culture/cms/surveys`, { params: query });
}

function getPulseSurvey(query) {
  return client(`culture/surveys`, { params: query });
}

function postSurveyCMSCulture(data) {
  return client(`culture/cms/surveys`, { method: 'POST', body: data });
}

function getSurveyIdCMSCulture(id, params) {
  return client(`culture/cms/surveys/${id}`, { params });
}

function getSurveyOccurencesPerId(id, params) {
  return client(`culture/cms/surveys/${id}/occurrences`, { params });
}

function patchSurveyIdCMSCulture(id, data) {
  return client(`culture/cms/surveys/${id}`, { method: 'PATCH', body: data });
}

function deleteSurveyIdCMSCulture(id) {
  return client(`culture/cms/surveys/${id}`, { method: 'DELETE', body: {} });
}

function getBreakdownSurveyAnalytics(id, params) {
  return client(`culture/cms/surveys/${id}/participants/answers`, {
    params: params
  });
}

function getCompletionRateSurvey(id, params) {
  return client(`culture/cms/surveys/${id}/analytics`, { params: params });
}

function sendPulseSurveyReminder(id, body) {
  const url = `culture/cms/surveys/${id}/send_reminder`;
  return client(url, { method: 'POST', body: { sendEmail: true, ...body } });
}

function getPulseSurveyParticipants(id, params) {
  return client(`culture/cms/surveys/${id}/participants`, { params: params });
}

function getBreakdownSurveyResult(id, params) {
  return client(`/culture/cms/surveys/${id}/results`, { params });
}

function getEssayResult(id, essay_id, params) {
  return client(`/culture/surveys/${id}/essays/${essay_id}/answers`, {
    params
  });
}

//=========================== CMS CULTURE Group Post =====================================
function getGroupPostCountCMSCulture(params) {
  return client(`culture/cms/group_post_counts`, {
    params: params
  });
}

function getGroupPostCountTotalCMSCulture(params) {
  return client(`culture/cms/group_post_counts/total`, {
    params: params
  });
}

//=========================== CMS CULTURE User Infos =====================================
function getUserInfosCMSCulture(key) {
  return client(`culture/cms/user_infos/${key}`);
}

function getCMSCulture(endpoint, params = {}) {
  let url = `${endpoint}?period_begin=${params.periodBegin}&period_end=${params.periodEnd}&time_unit=${params.timeUnit}`;
  let filterString = '';
  const customFilterCurr = params.customFilter || [];
  if (customFilterCurr.length !== 0) {
    for (var i = 0; i < customFilterCurr.length; i++) {
      Object.keys(customFilterCurr[i]).map((key) => {
        var keyValue = customFilterCurr[i][key].replace(' ', '+');

        filterString += `&filter[${i}][${key}]=${keyValue}`;
      });
    }
    url += filterString;
  }

  return client(url);
}

function getUserActivationCMSCulture(
  periodBegin,
  periodEnd,
  timeUnit,
  customFilter
) {
  return getCMSCulture('culture/cms/user_activations', {
    periodBegin,
    periodEnd,
    timeUnit,
    customFilter
  });
}

// ===========================CMS CULTURE user_stats/personal_growth ==================

function getUserStats(params) {
  return client('culture/cms/user_stats/personal_growth', {
    params: params
  });
}

function getListCollaboration(params) {
  return client('culture/cms/user_stats/collaboration', {
    params: params
  });
}

//=========================== CMS CULTURE Create Reminder =====================================
function postCreateReminder(data) {
  return client(`culture/cms/email`, { method: 'POST', body: data });
}

function getDownloadCSV(params) {
  return client(`culture/cms/posts`, { params: params });
}

//============================= INSIGHT/RETENTION=================================
function getRetentionActivity(data) {
  return client(`culture/cms/user_visits`, { params: data });
}

function getUserVisitsCMSCulture(
  periodBegin,
  periodEnd,
  timeUnit,
  customFilter
) {
  return getCMSCulture('culture/cms/user_visits', {
    periodBegin,
    periodEnd,
    timeUnit,
    customFilter
  });
}

function getAvaragePostsCMSCulture(
  periodBegin,
  periodEnd,
  timeUnit,
  customFilter
) {
  return getCMSCulture('culture/cms/average_posts', {
    periodBegin,
    periodEnd,
    timeUnit,
    customFilter
  });
}

function putNotificationSubcribe(payload) {
  return client(`/v1/notifications/subscription`, {
    method: 'PUT',
    body: payload
  });
}

function putNotificationSubcribeCulture(payload) {
  return client(`/culture/device`, {
    method: 'POST',
    body: payload
  });
}

function deleteNotificationUnSubcribe() {
  return client(`/v1/notifications/subscription`, {
    method: 'DELETE',
    body: true
  });
}

function exportActiveObjectivesCSV(payload) {
  return client('v3/objectives/active/exports', {
    method: 'POST',
    body: payload
  });
}

function exportPreviousActiveObjectivesCSV() {
  return client('v3/objectives/active/exports/latest', {
    method: 'POST',
    body: {}
  });
}

function getTotalActiveObjectives(params) {
  return client('v3/objectives/active/exports/total_objectives', { params });
}

function getExportActiveObjectivesHistory(params) {
  return client('v3/objectives/active/exports/history', { params });
}

function getDailyExportCount() {
  return client('v3/objectives/active/exports/daily_count');
}

function getPreviousExportParams() {
  return client('v3/objectives/active/exports/previous_params');
}

//=========================== CMS CULTURE MISSION =====================================
function postMission(data) {
  return client(`culture/cms/missions`, { method: 'POST', body: data });
}

function putUsingMission(data) {
  return client(`v1/admin/configs`, { method: 'PUT', body: data });
}

function checkMembersMission(id, data) {
  return client(`culture/cms/missions/${id}/check_members`, { params: data });
}

function updateMission(id, data) {
  return client(`culture/cms/missions/${id}`, { method: 'PATCH', body: data });
}

function getMissionList(params) {
  return client(`culture/cms/missions`, { params: params });
}
function getMissionbyId(id) {
  return client(`culture/cms/missions/${id}`, { params: {} });
}

function getMissionTemplate() {
  return client(`culture/cms/missions_templates`);
}

function deleteMission(id) {
  return client(`culture/cms/missions/${id}`, { method: 'DELETE', body: {} });
}

function getShareToken(id) {
  return client(`culture/missions/${id}/share_public`, {
    method: 'POST',
    body: {}
  });
}

function checkShareSurveyToken(params) {
  return client(`culture/cms/surveys/view_public`, { params });
}

function getShareSurveyToken(id, body) {
  return client(`culture/cms/surveys/${id}/share_public`, {
    method: 'POST',
    body
  });
}

function getDefaultBadge() {
  return client(`culture/cms/badges/default`, { params: {} });
}

function updateDefaultBadge(data) {
  return client(`culture/cms/badges/default`, { method: 'PATCH', body: data });
}

function getListLeagues(params) {
  return client(`culture/cms/leagues`, { params: params });
}

function getLeagueById(id) {
  return client(`culture/cms/leagues/${id}`, { params: {} });
}

function postLeague(data) {
  return client(`culture/cms/leagues`, { method: 'POST', body: data });
}

function updateLeague(id, data) {
  return client(`culture/cms/leagues/${id}`, { method: 'PATCH', body: data });
}

function restoreLeague(id) {
  return client(`culture/cms/leagues/${id}/restore`, {
    method: 'PATCH',
    body: {}
  });
}

function deleteLeague(id) {
  return client(`culture/cms/leagues/${id}`, { method: 'DELETE', body: {} });
}

function sortingListLeagues(body) {
  return client(`culture/cms/leagues_sort`, { method: 'PATCH', body: body });
}

function getMissionsAdjustmentHistory(params) {
  return client(`culture/cms/missions_adjustment_history`, { params: params });
}

function getUsersMissionAdjustmentHistory(userId) {
  return client(`v1/users/${userId}`);
}

function postMissionAdjustmentHistory(body) {
  return client(`culture/cms/missions/point_adjustment`, {
    method: 'POST',
    body: body
  });
}

function getListBadges(params) {
  return client(`culture/cms/project-badges`, { params: params });
}

function getBadgeById(id) {
  return client(`culture/cms/project-badges/${id}`, { params: {} });
}

function postBadge(data) {
  return client(`culture/cms/project-badges`, { method: 'POST', body: data });
}

function updateBadge(id, data) {
  return client(`culture/cms/project-badges/${id}`, {
    method: 'PATCH',
    body: data
  });
}

function restoreBadge(id) {
  return client(`culture/cms/project-badges/${id}/restore`, {
    method: 'PATCH',
    body: {}
  });
}

function deleteBadge(id) {
  return client(`culture/cms/project-badges/${id}/archive`, {
    method: 'DELETE',
    body: {}
  });
}

//=========================== Competencies & Competencies =================================

function getBehaviors(params) {
  return client(`behaviors`, {
    params
  });
}

function getBehaviorsModels(params) {
  return client(`v1/behavior_models`, {
    params
  });
}

function getSingleBehaviorsModels(id) {
  return client(`v1/behavior_models/${id}`);
}

function postBehaviors(body) {
  return client(`behaviors`, {
    method: 'POST',
    body
  });
}

function postBehaviorsModels(body) {
  return client(`v1/behavior_models`, {
    method: 'POST',
    body
  });
}

function putBehaviors(body) {
  return client(`behaviors`, {
    method: 'PUT',
    body
  });
}

function putBehaviorsModels(body) {
  return client(`v1/behavior_models`, {
    method: 'PUT',
    body
  });
}

function patchBehaviors(id, body) {
  return client(`behaviors/${id}`, {
    method: 'PATCH',
    body
  });
}

function patchBehaviorsModels(id, body) {
  return client(`v1/behavior_models/${id}`, {
    method: 'PATCH',
    body
  });
}

function getSurveyCount() {
  return client(`culture/surveys/count`);
}

function getFeedbackCount(params) {
  return client(`v1/formal_reviews/assignments/in_progress/count`, { params });
}

export {
  //=========================== SWITCH POSITION =================================
  getListSwitchPosition,
  updateSwitchPosition,
  //=========================== REVIEW TEMPLATE =================================
  getListTemplate,
  postTemplate,
  getTemplateById,
  editTemplateById,
  deleteTemplateById,
  //=========================== REVIEW GROUP ====================================
  getListUser,
  getListFilter,
  //=========================== MANAGE USER ====================================
  getUserInfo,
  importUsers,
  syncUser,
  syncSlack,
  installSlack,
  //=========================== CMS CULTURE TEAMS =====================================
  getTeamsCMSCulture,
  getTeamsIdCMSCulture,
  postTeamsCMSCulture,
  patchTeamsIdCMSCulture,
  deleteTeamsIdCMSCulture,
  postTeamsAddMemberCMSCulture,
  deleteTeamsRemoveMemberCMSCulture,
  //=========================== CMS CULTURE Organizations =====================================
  getOrganization,
  addOrganization,
  getOrganizationDetail,
  updateOrganization,
  deleteOrganization,
  //=========================== CMS CULTURE Leaderboard =====================================
  getLeaderboardsCMSCulture,
  getLeaderboardsUsersCMSCulture,
  getLeaderboardsPostsCMSCulture,
  //=========================== CMS CULTURE Label =====================================
  getLabelCMSCulture,
  postLabelCMSCulture,
  updateLabelCMSCulture,
  deleteLabelCMSCulture,
  //=========================== CMS CULTURE values =====================================
  getValues,
  addValues,
  updateValues,
  deleteValuesCMSCulture,
  getPreSignUrlValues,
  //=========================== CMS CULTURE groups =====================================
  getGroupCMSCulture,
  getGroupIdCMSCulture,
  //=========================== CMS CULTURE surveys =====================================
  getSurveyCMSCulture,
  getPulseSurvey,
  postSurveyCMSCulture,
  getSurveyIdCMSCulture,
  getSurveyOccurencesPerId,
  patchSurveyIdCMSCulture,
  deleteSurveyIdCMSCulture,
  getCompletionRateSurvey,
  getBreakdownSurveyAnalytics,
  sendPulseSurveyReminder,
  getPulseSurveyParticipants,
  getBreakdownSurveyResult,
  getEssayResult,
  //=========================== CMS CULTURE Group Post =====================================
  getGroupPostCountCMSCulture,
  getGroupPostCountTotalCMSCulture,
  //=========================== CMS CULTURE User INFOS  =====================================
  getUserInfosCMSCulture,
  getUserActivationCMSCulture,
  // ===========================CMS CULTURE user_stats/personal_growth ==================
  getUserStats,
  // ===========================CMS CULTURE HR Training =================================
  getListCollaboration,
  //=========================== CMS CULTURE Create Reminder =====================================
  postCreateReminder,
  //============================= INSIGHT/RETENTION=================================
  getUserVisitsCMSCulture,
  getRetentionActivity,
  getAvaragePostsCMSCulture,
  getDownloadCSV,
  putNotificationSubcribe,
  deleteNotificationUnSubcribe,
  putNotificationSubcribeCulture,
  exportActiveObjectivesCSV,
  exportPreviousActiveObjectivesCSV,
  getTotalActiveObjectives,
  getExportActiveObjectivesHistory,
  getDailyExportCount,
  getPreviousExportParams,
  //============================= MISSIONS=================================
  postMission,
  updateMission,
  getMissionList,
  getMissionbyId,
  deleteMission,
  getShareToken,
  getShareSurveyToken,
  checkShareSurveyToken,
  getMissionTemplate,
  checkMembersMission,
  putUsingMission,
  getListLeagues,
  getDefaultBadge,
  updateDefaultBadge,
  updateLeague,
  postLeague,
  deleteLeague,
  restoreLeague,
  sortingListLeagues,
  getLeagueById,
  getMissionsAdjustmentHistory,
  getUsersMissionAdjustmentHistory,
  postMissionAdjustmentHistory,
  getListBadges,
  getBadgeById,
  postBadge,
  updateBadge,
  restoreBadge,
  deleteBadge,
  //=========================== Competencies & Competencies =================================
  getBehaviors,
  getBehaviorsModels,
  getSingleBehaviorsModels,
  postBehaviors,
  postBehaviorsModels,
  putBehaviors,
  putBehaviorsModels,
  patchBehaviors,
  patchBehaviorsModels,
  //=========================== Permissions!! =================================
  getListGroupsPermission,
  updateStateGroupsPermission,
  updateGroupsPermission,
  postGroupsPermission,
  getGroupById,
  getUserPlacementPermission,
  patchUserPlacementPermission,
  getPlacementInfo,
  getPlacementInfoKey,
  //============================= Sidebar Survey =======================
  getSurveyCount,
  getFeedbackCount
};

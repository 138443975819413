function userAgentUtils() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  else if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  } else if (/Prerender/.test(userAgent)) {
    return 'Crawler';
  } else {
    return 'Web';
  }
}

export { userAgentUtils };

import React from 'react';

import PropTypes from 'prop-types';

import './DynamicTabGrey.scss';

const Interface = {
  nameDict: PropTypes.object,
  listName: PropTypes.array,
  state: PropTypes.string,
  setState: PropTypes.func,
  disabled: PropTypes.bool
};

const DynamicTabGrey = ({
  listName = ['score_only', 'score_and_label', 'label_only'],
  state = 'Kobe',
  setState,
  disabled = false,
  nameDict = {
    label_only: 'Hide rating and show label only',
    score_and_label: 'Rating with label',
    score_only: 'Rating without label'
  }
}) => {
  return (
    <div className="dynamic-tab-grey relative">
      {disabled && (
        <div className="z-index-99 absolute h-full w-full cursor-not-allowed rounded-md bg-n-500 opacity-50" />
      )}
      {listName.map((val, idx) => {
        const name = val?.name || val;
        return (
          <p
            data-cy={'grey-tab-' + name.replace(/ /g, '-').toLowerCase()}
            onClick={() => !val?.disabled && !disabled && setState(name)}
            className={`
              tab-name relative
              ${name == state ? 'active' : 'bg-n-000'}
              ${
                val?.disabled
                  ? '!cursor-not-allowed !border-none bg-n-500 opacity-50'
                  : 'state-secondary-purple'
              }
            `}
            key={idx}
          >
            {nameDict[val] || name}
          </p>
        );
      })}
    </div>
  );
};

DynamicTabGrey.propTypes = Interface;

export default DynamicTabGrey;

import firebase from 'firebase/app';
import 'firebase/messaging';

import {
  deleteNotificationUnSubcribe,
  putNotificationSubcribe,
  putNotificationSubcribeCulture
} from '../client/adminClient';
import { configCulture, configPerformance } from './FirebaseConfig';

export const unSubcribeFirebase = async () => {
  try {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
    const messaging = firebase.messaging();
    await messaging.deleteToken();

    await deleteNotificationUnSubcribe();
  } catch (e) {}
};

export const initializeFirebase = async (accessToken) => {
  try {
    const isFirebaseSuported = firebase.messaging.isSupported();
    if (isFirebaseSuported && 'serviceWorker' in navigator) {
      Notification.requestPermission().then(async function (permission) {
        if (permission === 'granted') {
          const handleServiceWorkerActive = (registration) => {
            if (registration.active) {
              const serviceWorker = registration.active;
              serviceWorker.postMessage({ configPerformance });
            }
          };

          navigator.serviceWorker
            .getRegistrations()
            .then(async (registrations) => {
              if (!registrations || registrations.length == 0) {
                await navigator.serviceWorker
                  .register('/firebase-messaging-sw.js')
                  .then(handleServiceWorkerActive);
                await navigator.serviceWorker.ready;

                navigator.serviceWorker.ready.then(async () => {
                  let performance = '';
                  if (firebase.apps.length == 0) {
                    performance = firebase.initializeApp(configPerformance);
                  } else {
                    performance = firebase.app();
                  }

                  const firebaseToken = await performance
                    .messaging()
                    .getToken();
                  localStorage.setItem(
                    'firebaseNotifPerfToken',
                    JSON.stringify(firebaseToken)
                  );
                  await putNotificationSubcribe({
                    device_type: 'android',
                    token: firebaseToken,
                    accessToken: accessToken
                  });
                });
              }
            });
        }
      });
    }
  } catch (e) {}
};

export const initializeFirebaseCulture = async (accessToken) => {
  try {
    const isFirebaseSuported = firebase.messaging.isSupported();
    if (isFirebaseSuported && 'serviceWorker' in navigator) {
      Notification.requestPermission().then(async function (permission) {
        if (permission === 'granted') {
          const handleServiceWorkerActive = (registration) => {
            if (registration.active) {
              const serviceWorker = registration.active;
              serviceWorker.postMessage({ configCulture });
            }
          };

          navigator.serviceWorker
            .getRegistrations()
            .then(async (registrations) => {
              if (!registrations) {
                await navigator.serviceWorker
                  .register('/firebase-messaging-culture-sw.js')
                  .then(handleServiceWorkerActive);
                await navigator.serviceWorker.ready;

                navigator.serviceWorker.ready.then(async () => {
                  let culture = '';

                  if (firebase.apps.length == 0) {
                    culture = firebase.initializeApp(
                      configCulture,
                      'cultureInitialize'
                    );
                  } else {
                    culture = firebase.app('cultureInitialize');
                  }

                  const cultureToken = await culture.messaging().getToken();

                  localStorage.setItem(
                    'firebaseNotifCultureToken',
                    JSON.stringify(cultureToken)
                  );
                  await putNotificationSubcribeCulture({
                    device: {
                      device_type: 'android',
                      token: cultureToken,
                      accessToken: accessToken
                    }
                  });
                });
              }
            });
        }
      });
    }
  } catch (e) {}
};

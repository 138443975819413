"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDownloader = exports.DownloaderProvider = void 0;
const react_1 = __importStar(require("react"));
const react_2 = require("react");
const ToastContext_1 = require("context/ToastContext");
const useLocale_1 = require("hooks/useLocale");
const CsvHelper_1 = require("utils/CsvHelper");
const socket_1 = require("utils/socket");
const initialState = {
    isDownloadInProcess: false,
    activeQueue: 0,
    data: {}
};
const defaultUpdate = () => initialState;
const DownloaderContext = (0, react_1.createContext)({
    state: initialState,
    setState: defaultUpdate
});
// PROVIDER
const DownloaderProvider = (props) => {
    const [state, setState] = (0, react_1.useState)(initialState);
    return react_1.default.createElement(DownloaderContext.Provider, { value: { state, setState }, ...props });
};
exports.DownloaderProvider = DownloaderProvider;
// MUTATION
const useDownloader = () => {
    const { state, setState } = (0, react_1.useContext)(DownloaderContext);
    const { addToast } = (0, ToastContext_1.useToastContext)();
    const { setBothLocale } = (0, useLocale_1.useLocale)();
    const setIsDownloadInProcess = (inProcess) => {
        setState((draft) => ({ ...draft, isDownloadInProcess: inProcess }));
    };
    const setData = (data) => {
        setState((draft) => ({ ...draft, data }));
    };
    const setActiveQueue = (type) => {
        setState((draft) => ({
            ...draft,
            activeQueue: type == 'increment' ? draft.activeQueue + 1 : draft.activeQueue - 1
        }));
    };
    const listener = react_1.default.useMemo(() => new socket_1.SocketListener(), []);
    const setupSocket = (openNewWindow, callbackConnected, showModal, successCallback) => {
        listener.register({
            name: socket_1.SocketChannels.backgroundJobProgress(),
            callback: (data) => {
                setData(data);
                const element = document.getElementById('export-csv-user');
                if (!openNewWindow && !element) {
                    addToast({
                        id: 'export-csv-user',
                        title: setBothLocale('Preparing file completed!'),
                        msg: setBothLocale('CSV has been prepared and the file will be downloaded automatically'),
                        type: 'success'
                    });
                }
                if (data?.object?.file &&
                    (data?.object?.state == 'done' || data?.state == 'done')) {
                    if (openNewWindow) {
                        showModal && showModal();
                        const newWindow = window.open(data?.object?.file, '_blank', 'noopener,noreferrer');
                        if (newWindow)
                            newWindow.opener = null;
                    }
                    else {
                        (0, CsvHelper_1.downloadExportCSV)(data?.object?.file);
                    }
                    setIsDownloadInProcess(false);
                    setActiveQueue('decrement');
                    listener.unregister();
                    successCallback && successCallback();
                }
            },
            callbackConnected: async () => {
                callbackConnected && (await callbackConnected());
            }
        });
    };
    (0, react_2.useEffect)(() => {
        if (state.activeQueue == 0) {
            listener.unregister();
        }
    }, [state.activeQueue, listener]);
    return {
        data: state?.data,
        isDownloadInProcess: state?.isDownloadInProcess,
        setIsDownloadInProcess,
        setupSocket,
        setActiveQueue
    };
};
exports.useDownloader = useDownloader;

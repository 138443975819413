let config = {};

try {
  config = ENVConfig;
} catch (error) {
  console.log('error', error);
}

const env = {
  PERFORMANCE_API_HOST:
    process.env.PERFORMANCE_API_HOST || config.PERFORMANCE_API_HOST,
  LOCALE_HOST: process.env.LOCALE_HOST || config.LOCALE_HOST,
  AMPLITUDE_KEY: process.env.AMPLITUDE_KEY || config.AMPLITUDE_KEY,
  AMPLITUDE_KEY_CULTURE:
    process.env.AMPLITUDE_KEY_CULTURE || config.AMPLITUDE_KEY_CULTURE,
  SENTRY_DSN: process.env.SENTRY_DSN || config.SENTRY_DSN,
  POC_ORGANIZATION: process.env.POC_ORGANIZATION || config.POC_ORGANIZATION,
  FIREBASE_API_KEY_PERFORMANCE:
    process.env.FIREBASE_API_KEY_PERFORMANCE ||
    config.FIREBASE_API_KEY_PERFORMANCE,
  FIREBASE_AUTH_DOMAIN_PERFORMANCE:
    process.env.FIREBASE_AUTH_DOMAIN_PERFORMANCE ||
    config.FIREBASE_AUTH_DOMAIN_PERFORMANCE,
  FIREBASE_PROJECT_ID_PERFORMANCE:
    process.env.FIREBASE_PROJECT_ID_PERFORMANCE ||
    config.FIREBASE_PROJECT_ID_PERFORMANCE,
  FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE:
    process.env.FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE ||
    config.FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE,
  FIREBASE_APP_ID_PERFORMANCE:
    process.env.FIREBASE_APP_ID_PERFORMANCE ||
    config.FIREBASE_APP_ID_PERFORMANCE,
  FIREBASE_API_KEY_CULTURE:
    process.env.FIREBASE_API_KEY_CULTURE || config.FIREBASE_API_KEY_CULTURE,
  FIREBASE_AUTH_DOMAIN_CULTURE:
    process.env.FIREBASE_AUTH_DOMAIN_CULTURE ||
    config.FIREBASE_AUTH_DOMAIN_CULTURE,
  FIREBASE_PROJECT_ID_CULTURE:
    process.env.FIREBASE_PROJECT_ID_CULTURE ||
    config.FIREBASE_PROJECT_ID_CULTURE,
  FIREBASE_MESSAGING_SENDER_ID_CULTURE:
    process.env.FIREBASE_MESSAGING_SENDER_ID_CULTURE ||
    config.FIREBASE_MESSAGING_SENDER_ID_CULTURE,
  FIREBASE_APP_ID_CULTURE:
    process.env.FIREBASE_APP_ID_CULTURE || config.FIREBASE_APP_ID_CULTURE,
  SENTRY_HOST: process.env.SENTRY_HOST || config.SENTRY_HOST,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  LOCALE_TOKEN: process.env.LOCALE_TOKEN || config.LOCALE_TOKEN,
  AUTHENTICATION_METHOD:
    process.env.AUTHENTICATION_METHOD || config.AUTHENTICATION_METHOD,
  ORGANIZATION_NAME: process.env.ORGANIZATION_NAME || config.ORGANIZATION_NAME,
  CULTURE_URL: process.env.CULTURE_URL || config.CULTURE_URL,
  PERFORMANCE_URL: process.env.PERFORMANCE_URL || config.PERFORMANCE_URL,
  AUTHORIZATION_METHOD:
    process.env.AUTHORIZATION_METHOD || config.AUTHORIZATION_METHOD || 'cookie',
  SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED:
    process.env.SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED ||
    config.SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
  OTP_PASS_TOKEN: process.env.OTP_PASS_TOKEN || config.OTP_PASS_TOKEN,
  RECAPTCHA_SITE_KEY:
    process.env.RECAPTCHA_SITE_KEY || config.RECAPTCHA_SITE_KEY,
  WEB_SOCKET_URL: process.env.WEB_SOCKET_URL || config.WEB_SOCKET_URL,
  ENABLE_MAINTENANCE_MODE: process.env.ENABLE_MAINTENANCE_MODE || config.ENABLE_MAINTENANCE_MODE
};

export default env;

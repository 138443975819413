import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import qs from 'qs';

import { logoutUser } from 'client/AuthClient';
import { getENV } from 'utils/HelperUtils';

import { toCamelCase, toSnakeCase } from '../utils/caseConverter';
import { Axios } from './Axios';
import { buildErrorData, handleSentry } from './helperClient';

// set default timeout for axios
Axios.defaults.timeout = 60000;

function client(endpoint, params, noConvertCase) {
  const authorizationMethod = getENV('AUTHORIZATION_METHOD');
  const token = localStorage.getItem('bearer');
  const OTP_PASS_TOKEN = getENV('OTP_PASS_TOKEN');

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(authorizationMethod === 'bearer' &&
      token && { Authorization: 'Bearer ' + token }),
    ...(OTP_PASS_TOKEN && { 'OTP-Pass-Token': OTP_PASS_TOKEN })
  };

  const url = getENV('PERFORMANCE_API_HOST');

  const config = {
    url: `${url}/${endpoint}`,
    headers
  };

  if (authorizationMethod === 'cookie') {
    Axios.defaults.withCredentials = true;
  }

  if (!isEmpty(params)) {
    params = toSnakeCase(params);

    config.url =
      config.url +
      '?' +
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: true,
        skipNulls: true
      });
  }
  return Axios(config).then(
    (response) => {
      const { data, pagination } = noConvertCase
        ? response.data
        : toCamelCase(response.data);
      const metadata = toCamelCase(omit(response.data, ['data', 'pagination']));
      return {
        data,
        metadata,
        pagination
      };
    },
    async (e) => {
      if (e?.response?.status === 401 && endpoint !== 'v1/auth') {
        await logoutUser();
        window.location.assign(window.location);
        return Promise.reject({ message: 'Please relogin again.' });
      }

      let messageRejection =
        e?.response?.data?.error?.message || e?.response?.data?.errors?.message;

      if (e?.response?.status === 'ECONNABORTED') {
        messageRejection = 'Time Out Please Try Again';
      } else if (!messageRejection) {
        messageRejection = 'Something went wrong';
      }

      const error = buildErrorData(e);
      if (e?.response?.status !== 401 && endpoint !== 'v1/auth') {
        handleSentry({
          error: error,
          endpoint: endpoint,
          config: config,
          method: error?.response?.config?.method,
          raw: e
        });
      }
      return Promise.reject({ message: messageRejection });
    }
  );
}

export default client;

import env from 'utils/env';

export const configPerformance = {
  apiKey: env.FIREBASE_API_KEY_PERFORMANCE,
  authDomain: env.FIREBASE_AUTH_DOMAIN_PERFORMANCE,
  projectId: env.FIREBASE_PROJECT_ID_PERFORMANCE,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE,
  appId: env.FIREBASE_APP_ID_PERFORMANCE
};

export const configCulture = {
  apiKey: env.FIREBASE_API_KEY_CULTURE,
  authDomain: env.FIREBASE_AUTH_DOMAIN_CULTURE,
  projectId: env.FIREBASE_PROJECT_ID_CULTURE,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID_CULTURE,
  appId: env.FIREBASE_APP_ID_CULTURE
};

import axios from 'axios';
import hexToHsl from 'hex-to-hsl';
import get from 'lodash/get';
import tldExtract from 'tld-extract';

import env from 'utils/env.js';

var formattedNumber = function (number) {
  return new Intl.NumberFormat('id-ID').format(number);
};

var parseNewlinetoBr = function (text) {
  if (typeof text === 'string' && text) {
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }
  return text;
};

var parseUrlinString = function (text, style) {
  if (typeof text === 'string' && text) {
    var urlExpression = /&#x2F;/gi;
    text = text.replace(urlExpression, '/');

    var expression =
      // eslint-disable-next-line
      /([-a-zA-Z0-9@:%_\+~#?&//=]([.][-a-zA-Z0-9@:%_\+~#?&//=])*){1,256}\.[a-z]{2,4}\b(\/([-a-zA-Z0-9]+[@:%_\+.~#?&//=]?)*)?/gi;
    var regex = new RegExp(expression);

    var matched = text.match(regex);
    if (matched) {
      var convertUrl = function (match) {
        var pattern = /^((http|https):\/\/)/;
        var url = match;
        if (!url.match(pattern)) url = 'http://' + url;

        return `<a href=${url} target="_blank" style=${style}>${match}</a>`;
      };

      text = text.replace(regex, convertUrl);
    }
  }

  return text;
};

var taggedUserTemplate = function (userObj) {
  var text = '';
  if (typeof userObj === 'object') {
    var userNumber = Object.keys(userObj).length;
    if (userNumber !== 0) {
      text = ' &#8212; With';
      var showUserLimit = 3;
      if (userNumber === 4) showUserLimit = 2;

      for (var i = 0; i < userNumber; i++) {
        if (i < showUserLimit) {
          text += i === 0 ? ' ' : ', ';
          text += '<b>' + userObj[i].fullName + '</b>';
        }
      }

      if (userNumber > 3) {
        text +=
          ", and <b id='many-more' data-cy='many-more-text' class='cursor-pointer'>many more</b>";
      }
    }
  }

  return text;
};

var imageIsValid = function (image) {
  var isValid = false;
  if (
    image !== '#' &&
    image !== null &&
    image !== '' &&
    typeof image === 'string'
  )
    isValid = true;

  return isValid;
};

var parseHttptoHttps = function (text) {
  if (typeof text === 'string' && text !== null && text !== '') {
    text = text.replace('http://', 'https://');
  }

  return text;
};

var replaceMatched = function (text, expression, className) {
  var regex = new RegExp(expression);
  var matched = text.match(regex);
  if (matched) {
    var convertMention = function (match) {
      return `<span class="${className}">` + match + '</span>';
    };

    return text.replace(regex, convertMention);
  }
  return text;
};

var parseHashtaginString = function (text) {
  if (typeof text === 'string' && text) {
    var expression = /[\t\n ]#([A-Za-z0-9]+)|^#([A-Za-z0-9]+)/gi;
    text = replaceMatched(text, expression, 'caption-hashtag');
  }

  return text;
};

var parseMentioninString = function (text, metaMentions) {
  if (typeof text === 'string' && text && metaMentions) {
    var user = [];

    for (var i = 0; i < metaMentions.length; i++) {
      user.push(
        text.substr(metaMentions[i].startIndex, metaMentions[i].length)
      );
    }

    for (var j = 0; j < user.length; j++) {
      var expression = user[j];
      text = replaceMatched(text, expression, 'caption-mention');
    }

    return text;
  }
};

var parseCaption = function (text, metaMentions) {
  if (metaMentions) {
    text = parseMentioninString(text, metaMentions);
  }
  text = parseHashtaginString(text);
  text = parseUrlinString(text);
  text = parseNewlinetoBr(text);

  return text;
};

var htmlToText = function (text) {
  var expression = /&nbsp;/gi;
  var regex = new RegExp(expression);
  var match = text.match(regex);
  if (match) {
    text = text.replace(regex, ' ');
  }

  expression = /<br>$/gi;
  regex = new RegExp(expression);
  match = text.match(regex);
  if (match) {
    text = text.replace(regex, '');
  }

  // eslint-disable-next-line
  expression = /<br\s*[\/]?>/gi;
  regex = new RegExp(expression);
  match = text.match(regex);
  if (match) {
    text = text.replace(regex, '\n');
  }

  return text;
};

var translateMarkdown = function (text) {
  text = text?.replace(/\*([^*]+)\**/g, '<b>$1</b>');
  text = text?.replace(/\*/g, '');
  return text;
};

var getLocalTeamName = function () {
  return localStorage.performanceTeamName;
};

var getDeviceId = function () {
  return localStorage.deviceId;
};

var getProvisionToken = function () {
  return localStorage.provisionToken;
};

var getUserToken = function () {
  return localStorage.userToken;
};

var getLocalAppName = function () {
  return localStorage.performanceAppName;
};

var getLocalUserInfo = function () {
  return localStorage.getItem('performanceUserInfo');
};

var getLocale = function (searchKey, lowercase, locales) {
  const prefix = locales == 'objectiveLocales' ? 'OL_' : 'CL_';
  const res = localStorage.getItem(prefix + searchKey);
  if (!res) return searchKey;
  if (lowercase) return res.toLowerCase();
  return res;
};

var getObjectiveLocale = function (
  searchKey,
  lowercase = false,
  locales = 'objectiveLocales'
) {
  return getLocale(searchKey, lowercase, locales);
};

var getDateLocale = function (c, formatDate) {
  // See formatDate/options here: https://www.w3schools.com/jsref/jsref_tolocalestring.asp
  return new Date(c).toLocaleString(
    navigator.language,
    formatDate ? formatDate : { month: 'long', day: 'numeric', year: 'numeric' }
  );
};

var formattedFloat = function (c) {
  if (c === parseInt(c)) {
    return c.toFixed(1);
  }
  return c ? c.toFixed(2) : c;
};

var getNumberLocale = function (c, loc, options = {}) {
  const number = typeof c != 'string' ? parseFloat(c?.toFixed(2)) : c;
  return number?.toLocaleString(loc ? loc : navigator.language, options);
};

const milestoneStringValueFormatter = (inputValue) => {
  if (!inputValue) return inputValue;
  if (!inputValue.toString().includes(':')) {
    return getNumberLocale(parseFloat(inputValue));
  }
  const value = inputValue.toString().replaceAll(' ', '');
  const splittedValue = value
    .split(',')
    .map((v) => {
      const result = v.split(':');
      result[1] = getNumberLocale(parseFloat(result[1]));
      return result.join(': ');
    })
    .join('; ');
  return splittedValue;
};

var getCultureLocale = function (
  searchKey,
  lowercase = false,
  locales = 'cultureLocales'
) {
  return getLocale(searchKey, lowercase, locales);
};

var removeAllLocalStorage = function () {
  var key = [
    'performanceTeamName',
    'performanceToken',
    'performanceUserInfo',
    'performanceAppName',
    'performanceTeamLogo',
    'appStatus',
    'objectiveLocale'
  ];

  for (var i in key) {
    localStorage.removeItem(key[i]);
  }
};

var unregisterServiceWorker = function () {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    if (!registrations.length) {
      return;
    }
    for (const registration of registrations) {
      registration.unregister();
    }
  });
};

var loadMoreValidator = function (target, threshold, callback) {
  threshold = parseInt(threshold);
  if (
    target.scrollTop + target.clientHeight >=
    target.scrollHeight - threshold
  ) {
    callback();
  }
};

var countObjectives = function (objectives, n = 1) {
  // n start at 1
  // because we count the parent at start

  // sub goal 3 or last children doesn't have children object
  if (
    typeof objectives.children === 'undefined' ||
    objectives.children.length === 0
  ) {
    return n;
  }

  objectives.children.map((objectives) => {
    n = countObjectives(objectives, ++n);
  });

  return n;
};

const capitalize = (word = '') =>
  word?.charAt(0)?.toUpperCase() + word?.slice(1);

const capitalizeSentence = (sentence = '') => {
  const listWordSentence = sentence.split(' ');
  let sentencesCapitalize = '';
  for (let i = 0; i < listWordSentence.length; i++) {
    sentencesCapitalize += `${i > 0 ? ' ' : ''}${
      listWordSentence[i]?.charAt(0)?.toUpperCase() +
      listWordSentence[i]?.slice(1)
    }`;
  }
  return sentencesCapitalize;
};

const camelCaseToSentence = (word = '') =>
  word
    .match(/([A-Z]?[^A-Z]*)/g)
    .slice(0, -1)
    .join(' ');

const arrayToSentence = (array = [], useCapitalize = false) => {
  if (array?.length === 0) return '';

  const checkCapitalize = (word) => {
    return useCapitalize ? capitalize(word) : word;
  };

  if (array?.length === 1) return checkCapitalize(array[0]);
  if (array?.length === 2)
    return checkCapitalize(array[0]) + ' and ' + checkCapitalize(array[1]);

  let sentence = '';
  for (let i = 0; i < array?.length; i++) {
    if (i > 0) {
      sentence += ', ' + (i === array?.length - 1 ? 'and ' : '');
    }
    sentence += checkCapitalize(array[i]);
  }
  return sentence;
};

const strToMiddleEllipsis = (maxFirstLength, text) =>
  text.length > maxFirstLength
    ? text.substr(
        0,
        text.length - 15 > maxFirstLength ? maxFirstLength : text.length - 15
      ) +
      '...' +
      text.substr(text.length - 10, text.length)
    : text;

const setLocalStorage = (key, value) => window.localStorage.setItem(key, value);
const getLocalStorage = (key) => window.localStorage.getItem(key);
const removeLocalStorage = (key) => window.localStorage.removeItem(key);

const formatBytes = (bytes) => {
  if (bytes == 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const convertBytes = (bytes, newSize) => {
  if (bytes == 0) return 0;
  const k = 1024;
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const sizeIndex = sizes?.findIndex((size) => newSize == size);
  if (sizeIndex == -1) return bytes;
  return parseFloat(bytes / Math.pow(k, sizeIndex + 1));
};

const lowerCaseKeys = (object) => {
  const lowerCaseObject = {};
  for (const [key, value] of Object.entries(object)) {
    lowerCaseObject[key.toLowerCase()] = value;
  }

  return lowerCaseObject;
};

const convertDataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const getThumbnailVideo = (file) => {
  const filePromise = new Promise((resolve) => {
    //get video data base64
    let videoSrc = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (r) => {
      videoSrc = r.target.result;
    };

    //get video data + canvas thumbnail
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement('video');
      const timeupdate = () => {
        if (getThumbnailPreview()) {
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }
      };
      const getThumbnailPreview = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        if (canvas.toDataURL().includes('data:image')) {
          const currentPhoto = document.createElement('video');
          canvas.className = 'aClassName';
          currentPhoto.type = 'video';
          currentPhoto.src = canvas.toDataURL();
          currentPhoto.fileThumbnail = convertDataURLtoFile(
            canvas.toDataURL(),
            'thumbnailVideo.jpg'
          );
          currentPhoto.videoSrc = videoSrc;
          currentPhoto.fileExtension = file.name.split('.').pop().toLowerCase();
          currentPhoto.file = file;
          currentPhoto.width = video.videoWidth;
          currentPhoto.height = video.videoHeight;
          resolve(currentPhoto);
          URL.revokeObjectURL(url);
          return true;
        }
        return false;
      };
      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.load();
      video.play();
    };
    fileReader.readAsArrayBuffer(file);
  });
  return { filePromise };
};

function getBase64(url) {
  return (
    axios
      .get(url, {
        responseType: 'arraybuffer'
      })
      .then((response) =>
        Buffer.from(response.data, 'binary').toString('base64')
      )
      // eslint-disable-next-line
      .catch(() => console.log('favicon cannot be loaded'))
  );
}

const setMetaLinkTag = async (logo, assets, applicationName) => {
  if (logo) {
    const response = await getBase64(logo);
    const base64Logo = 'data:image/png;base64,' + response;

    const link = document.querySelector('link[id="shortcut icon"]');

    if (link) {
      link.href = base64Logo;
    }

    const linkIcon = document.querySelector('link[rel="icon"]');
    if (linkIcon) {
      linkIcon.href = base64Logo;
    }

    const iconLink32 = document.querySelector('link[id="icon-32-2"]');
    if (iconLink32) {
      iconLink32.href = base64Logo;
    }

    const appleLink = document.querySelector('link[rel="apple-touch-icon 2"]');
    if (appleLink) {
      appleLink.href = base64Logo;
    }

    const iconLink16 = document.querySelector('link[id="icon-16-2"]');
    if (iconLink16) {
      iconLink16.href = base64Logo;
    }

    const safariLink = document.querySelector('link[rel="mask-icon 2"]');
    if (safariLink) {
      safariLink.href = base64Logo;
    }
  }

  if (assets?.mainColor) {
    const tileColor = document.querySelector(
      'meta[name="msapplication-TileColor"]'
    );
    tileColor.content = `hsl(${assets.mainColor}, 80%, 45%)`;

    const themeColor = document.querySelector('meta[name="theme-color"]');
    themeColor.content = `hsl(${assets.mainColor}, 80%, 45%)`;
  }

  if (applicationName) {
    const appleTitle = document.querySelector(
      'meta[name="apple-mobile-web-app-title"]'
    );
    appleTitle.content = applicationName;

    const appName = document.querySelector('meta[name="application-name"]');
    appName.content = applicationName;
    document.title = applicationName;
  }
};
const suffixChecker = (value) => {
  if (value < 1000) return 0;
  if (value >= 1000 && value < Math.pow(1000, 2)) return 1;
  if (value >= Math.pow(1000, 2) && value < Math.pow(1000, 3)) return 2;
  if (value >= Math.pow(1000, 3) && value < Math.pow(1000, 4)) return 3;
  if (value >= Math.pow(1000, 4)) return 4;
};

const toFixed = (num, fixed) => {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num?.toString().match(re)[0];
};

const abbrevNumber = (
  value,
  precision = 2,
  floorPrecision = false,
  useSpace = true
  // eslint-disable-next-line max-params
) => {
  const suffixes = [
    '',
    getObjectiveLocale('K'),
    getObjectiveLocale('M'),
    getObjectiveLocale('B'),
    getObjectiveLocale('T')
  ];
  let suffixesNumber = suffixChecker(Math.abs(value));
  suffixesNumber = suffixesNumber > 4 ? 4 : suffixesNumber;
  let result = value;

  if (Math.abs(value) >= 1000) {
    let shortNumber = 0;
    //numbers are rounded down
    if (floorPrecision) {
      shortNumber =
        Math.floor(
          (value / Math.pow(1000, suffixesNumber)) * Math.pow(10, precision)
        ) / Math.pow(10, precision);
    } else {
      shortNumber = toFixed(value / Math.pow(1000, suffixesNumber), precision);
    }
    result =
      shortNumber.toLocaleString(navigator.language) +
      (useSpace ? ' ' : '') +
      suffixes[suffixesNumber];
  } else {
    if (floorPrecision) {
      result =
        Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);
    } else {
      result = toFixed(value, precision);
    }
  }

  return result;
};

const isQuarter = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const hash = { 1: 3, 4: 6, 7: 9, 10: 12 };
  const lastDateOfTheEndMonth = new Date(
    new Date().getFullYear(),
    end.getMonth() + 1,
    0
  ).getDate();

  if (
    hash[start.getMonth() + 1] == end.getMonth() + 1 &&
    start.getDate() == 1 &&
    end.getDate() == lastDateOfTheEndMonth
  ) {
    return true;
  }

  return false;
};

const copyToClipboard = (string) => {
  let textarea;
  let result;
  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed';
    textarea.value = string;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    const range = document.createRange();
    range.selectNodeContents(textarea);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }
  if (!result && !window.Cypress) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string);
    if (!result) {
      return false;
    }
  }
  return true;
};

const getLines = (ref) => {
  const element = getComputedStyle(ref.current);
  const height = parseInt(element.height);
  const lineHeight = parseInt(element.lineHeight);

  const lines = height / lineHeight;

  return lines;
};

function getUniqueArrayOfObjects(arr, keyComparison) {
  // store the comparison values in array
  const unique = arr
    .map((e) => e[keyComparison])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

const getNested = (object, dotSeparatedKey) => {
  const customMatcher = /\[.*=.*\]/g;
  let fallback;
  if (dotSeparatedKey?.[0] === '/') fallback = dotSeparatedKey.slice(1);
  else if (dotSeparatedKey?.[0] === '&') fallback = !!dotSeparatedKey[1];

  if (dotSeparatedKey.match(customMatcher)) {
    let val = object;
    dotSeparatedKey.split('.').map((key) => {
      if (key) {
        if (key[0] === '[') {
          const [subKey, subVal] = key.slice(1, -1).split('=');
          val = val?.filter((k) => k[subKey] === subVal)?.[0];
        } else {
          val = val[key];
        }
      }
    });
    return val;
  }
  return get(object, dotSeparatedKey, fallback || object);
};

const setPostType = (type) => {
  const isMultipleMedia = type?.includes('_');
  if (isMultipleMedia) {
    let changesType = type?.split('_');
    changesType[1] = capitalize(changesType[1]);
    changesType = changesType.join('');
    return changesType;
  }
  return type;
};

const getENV = (key) => {
  const lsEnv = JSON.parse(localStorage.getItem('env') || '{}');
  const redLsEnv = JSON.parse(localStorage.getItem('redLsEnv') || '{}');
  const customApiHost = {
    PERFORMANCE_API_HOST: localStorage.getItem('apiHost') || ''
  };

  return redLsEnv?.[key] || lsEnv?.[key] || customApiHost?.[key] || env?.[key];
};

const getCssVariableValue = (variable) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .replace('#', '')
    .trim();
};

const createResource = (promise) => {
  let status = 'pending';
  let result = promise.then(
    (resolved) => {
      status = 'success';
      result = resolved;
    },
    (rejected) => {
      status = 'error';
      result = rejected;
    }
  );
  return {
    read() {
      if (status === 'pending') throw result;
      if (status === 'error') throw result;
      if (status === 'success') return result;
      throw new Error('This should be impossible');
    }
  };
};

// To get UTC + or - and its value, e.g: "(UTC+7)" | "(UTC-3)"
const getUTCOffset = () => {
  const offset = new Date().getTimezoneOffset();
  let utcHours;
  if (offset < 0) {
    utcHours = `(UTC+${offset / -60})`;
  } else {
    utcHours = `(UTC-${offset / 60})`;
  }
  return utcHours;
};

const getHue = (hex) => {
  const hsl = hexToHsl(hex);
  return hsl[0];
};

const getNumberWithOrdinal = (n) => {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]) + ' ';
};

const setActiveNavigator = (
  e,
  listSection,
  setActiveSection,
  additionalOffset = 0
) => {
  const scrollTop = e.target.scrollTop + additionalOffset;
  const listSectionId = listSection.map((section) => section.id);

  listSectionId.forEach((section) => {
    const el = document.getElementById(section);
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection(section);
    }
    if (e.target.scrollTop + e.target.offsetHeight == e.target.scrollHeight) {
      setActiveSection(listSectionId[listSectionId.length - 1]);
    }
  });
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const validateEmail = (email) => {
  const Regex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Regex.test(email);
};

const mappingExtension = (mappingExtension) => {
  const list = mappingExtension
    .map((ext) => {
      if (ext.match(/[a-zA-Z]\//)) {
        return ext;
      }
      if (ext.charAt(0) === '/') {
        ext = ext.slice(1, ext.length);
      }
      ext = '.' + ext;
      return ext;
    })
    .filter((ext) => ext);

  return list;
};

const mappingColor = (value, type = 'full') => {
  let colorBackground, bgColorHex, twBg, twText;
  switch (value?.toUpperCase()) {
    case '78350F':
      colorBackground = 'amber';
      twBg = 'bg-a-400';
      twText = 'text-a-500';
      bgColorHex = 'FEF3C7';
      break;
    case '1E3A8A':
      colorBackground = 'blue';
      twBg = 'bg-b-400';
      twText = 'text-b-500';
      bgColorHex = 'DBEAFE';
      break;
    case '881337':
      colorBackground = 'crimson';
      twBg = 'bg-c-400';
      twText = 'text-c-500';
      bgColorHex = 'FFE4E6';
      break;
    case '164E63':
      colorBackground = 'cyan';
      twBg = 'bg-cy-400';
      twText = 'text-cy-500';
      bgColorHex = 'CFFAFE';
      break;
    case '064E3B':
      colorBackground = 'emerald';
      twBg = 'bg-e-400';
      twText = 'text-e-500';
      bgColorHex = 'D1FAE5';
      break;
    case '701A75':
      colorBackground = 'fuschia';
      twBg = 'bg-f-400';
      twText = 'text-f-500';
      bgColorHex = 'FAE8FF';
      break;
    case '14532D':
      colorBackground = 'green';
      twBg = 'bg-g-400';
      twText = 'text-g-500';
      bgColorHex = 'DCFCE7';
      break;
    case '312E81':
      colorBackground = 'indigo';
      twBg = 'bg-i-400';
      twText = 'text-i-500';
      bgColorHex = 'E0E7FF';
      break;
    case '0C4A6E':
      colorBackground = 'light blue';
      twBg = 'bg-lb-400';
      twText = 'text-lb-500';
      bgColorHex = 'E0F2FE';
      break;
    case '365314':
      colorBackground = 'lime';
      twBg = 'bg-l-400';
      twText = 'text-l-500';
      bgColorHex = 'ECFCCB';
      break;
    case '7C2D12':
      colorBackground = 'orange';
      twBg = 'bg-o-400';
      twText = 'text-o-500';
      bgColorHex = 'FFEDD5';
      break;
    case '134E4A':
      colorBackground = 'teal';
      twBg = 'bg-t-400';
      twText = 'text-t-500';
      bgColorHex = 'CCFBF1';
      break;
    case '713F12':
      colorBackground = 'yellow';
      twBg = 'bg-y-400';
      twText = 'text-y-500';
      bgColorHex = 'FEF9C3';
      break;
  }
  return type === 'full'
    ? { colorBackground, twBg, bgColorHex }
    : type === 'tw'
    ? { twBg, twText }
    : bgColorHex;
};

const convertStateToParam = (state) => {
  return state
    .split(' ')
    .map((string) => string.toLowerCase())
    .join('-');
};

const toggleDropdown = (open, setAnimation, setOpen) => {
  setAnimation(
    open
      ? 'animation-open-dropdown-fadeInDownSlow'
      : 'animation-close-dropdown-rotate'
  );
  setTimeout(() => setOpen(open), open ? 0 : 150);
};

const setDropdownOptions = (
  dataList,
  textProp,
  onClickFunction,
  onClickProp,
  iconName,
  extraParams
) => {
  const options = [];
  dataList?.map((data) =>
    options.push({
      text: (textProp ? data[textProp] : data)?.replaceAll('_', ' '),
      iconName: iconName,
      onClick: () =>
        onClickProp
          ? onClickFunction(data[onClickProp])
          : onClickFunction(data, extraParams)
    })
  );
  return options;
};

const countDecimals = (value) => {
  if (!value) return 0;

  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const getDurationDate = (date1, date2) => {
  const diff = new Date(date1) - new Date(date2);

  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return {
    seconds,
    minutes,
    hours,
    days
  };
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url, {
    headers: { pragma: 'no-cache', 'cache-control': 'no-cache' }
  });
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const getFileFromDataUrl = (dataUrl, fileName) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

const getExtraInformation = (title, subtitle) => {
  if (!title) return '-';
  if (subtitle) return `${title} · ${subtitle}`;
  return title;
};

const isValidUrl = (s) => {
  const regexp =
    // eslint-disable-next-line no-useless-escape
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(s);
};

const getTruncateNumber = (number) => {
  let text;
  if (String(number).length > 5) {
    text = abbrevNumber(number, 2, true) + '..';
  } else {
    text = number;
  }

  return text;
};

const convertStringToFloat = (value) => {
  const decimalSeparator = (1.1)
    .toLocaleString(navigator.language)
    .substring(1, 2);

  const thousandsSeparator = (1111111)
    .toLocaleString(navigator.language)
    .substring(1, 2);

  const newValue = value
    .replace(thousandsSeparator, '')
    .replace(decimalSeparator, '.');
  return parseFloat(newValue);
};

const validatePassword = (password) => {
  const Regex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/;
  return Regex.test(password);
};

const calculateNumber = (level, initialValue, increment) => {
  return initialValue + (level - 1) * increment;
};

const flattenPages = (pages) => {
  return (
    pages
      ?.flatMap((page) => {
        if (!page.isSuccess) return;
        return page?.data;
      })
      .filter((d) => d) || []
  );
};

const convertAbbreviationName = (name) => {
  if (!name) return;
  if (!name.trim().includes(' ')) return name;
  const splitName = name.split(' ');
  return `${splitName?.[0]} ${splitName?.[1].substring(0, 1)}.`;
};

const removeArrayBySplice = (initialArray, idToRemove, propToCheck) => {
  const newArray = initialArray;
  const indexToRemove = initialArray?.findIndex(
    (value) => (propToCheck ? value?.[propToCheck] : value) === idToRemove
  );
  if (indexToRemove === -1) return initialArray;
  newArray?.splice(indexToRemove, 1);
  return newArray;
};

const regexISOFull = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
const regexISO = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/;

const isISODate = (str) => {
  const isISOStringFull = regexISOFull.test(str);
  const isISOString = regexISO.test(str);
  if (!isISOString && !isISOStringFull) return false;
  const d = new Date(str);
  if (!(d instanceof Date)) return false;
  if (isISOStringFull) {
    return !isNaN(d.getTime()) && d.toISOString() === str;
  }

  return !isNaN(d.getTime()) && d.toISOString().split('.')[0] + 'Z' === str;
};

const openFilePreview = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const romanize = (num) => {
  const lookup = {
    m: 1000,
    cm: 900,
    d: 500,
    cd: 400,
    c: 100,
    xc: 90,
    l: 50,
    xl: 40,
    x: 10,
    ix: 9,
    v: 5,
    iv: 4,
    i: 1
  };
  let roman = '';
  let i;
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
};

const whatDecimalSeparator = () => {
  var n = 1.1;
  return n.toLocaleString(navigator.language).substring(1, 2);
};

const whatNumberSeparator = () => {
  var n = 1111111;
  return n.toLocaleString(navigator.language).substring(1, 2);
};

const getDomainFromUrl = (hostname) => {
  if (!hostname) return null;
  return tldExtract(`https://${hostname}`).domain;
};

const waitElement = (selector, targetObserve, timeout = 10000) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      // too overpowered and performance cost
      if (document.querySelector(selector)) {
        observer.disconnect();
        return resolve(document.querySelector(selector));
      }

      // performance improved but there is unhandled edge case race conditions
      // mutationList.forEach((mutation) => {
      //   for (const node of mutation.addedNodes) {
      //     if (node?.matches && node.matches(selector)) {
      //       observer.disconnect();
      //       resolve(document.querySelector(selector));
      //       break;
      //     }
      //   }
      // });
    });

    observer.observe(targetObserve || document.body, {
      childList: true,
      subtree: true
    });

    setTimeout(() => observer.disconnect(), timeout);
  });
};

// type of replaceTargetObj = {
//   identifier?: string (ex: [target-element]),
//   replacementElement?: HTMLElement
// }
const localeElementParser = ({ keyString, replaceTargetObj }) => {
  const SEPARATOR = '~|~';
  let localedText = getObjectiveLocale(keyString);
  replaceTargetObj.forEach((target) => {
    if (!target.identifier || !target.replacementElement) return;
    const indexPos = localedText.indexOf(target.identifier);
    if (localedText.indexOf(target.identifier) > -1) {
      const textarr = localedText.split('');
      textarr.splice(indexPos + target.identifier.length, 0, SEPARATOR);
      textarr.splice(indexPos, 0, SEPARATOR);
      localedText = textarr.join('');
    }
  });

  return localedText.split(SEPARATOR).map((element) => {
    const targetIndex = replaceTargetObj.findIndex(
      (target) => target?.identifier == element
    );
    if (targetIndex < 0) return element;
    return replaceTargetObj[targetIndex].replacementElement;
  });
};

export {
  formattedNumber,
  parseNewlinetoBr,
  parseUrlinString,
  parseHashtaginString,
  parseMentioninString,
  taggedUserTemplate,
  parseHttptoHttps,
  imageIsValid,
  parseCaption,
  htmlToText,
  getLocalTeamName,
  getLocalAppName,
  getLocalUserInfo,
  removeAllLocalStorage,
  loadMoreValidator,
  countObjectives,
  getObjectiveLocale,
  getDateLocale,
  getNumberLocale,
  unregisterServiceWorker,
  capitalize,
  strToMiddleEllipsis,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  getUserToken,
  getDeviceId,
  getProvisionToken,
  formatBytes,
  convertBytes,
  lowerCaseKeys,
  getThumbnailVideo,
  setMetaLinkTag,
  copyToClipboard,
  getLines,
  getNested,
  getUniqueArrayOfObjects,
  setPostType,
  translateMarkdown,
  getENV,
  getCssVariableValue,
  getCultureLocale,
  capitalizeSentence,
  camelCaseToSentence,
  arrayToSentence,
  createResource,
  getUTCOffset,
  getHue,
  getNumberWithOrdinal,
  setActiveNavigator,
  openInNewTab,
  validateEmail,
  mappingExtension,
  mappingColor,
  abbrevNumber,
  convertStateToParam,
  toggleDropdown,
  setDropdownOptions,
  countDecimals,
  getDurationDate,
  formattedFloat,
  getBase64FromUrl,
  getFileFromDataUrl,
  getExtraInformation,
  isValidUrl,
  getTruncateNumber,
  convertStringToFloat,
  validatePassword,
  calculateNumber,
  flattenPages,
  convertAbbreviationName,
  removeArrayBySplice,
  milestoneStringValueFormatter,
  isISODate,
  openFilePreview,
  romanize,
  whatDecimalSeparator,
  whatNumberSeparator,
  getDomainFromUrl,
  waitElement,
  localeElementParser
};

import client from './ApiClient';

function getUser(userId, query) {
  const url = `v1/users/${userId}`;
  return client(url, { params: query });
}

function getUserCulture(userId, params) {
  const url = `culture/users/${userId}`;
  return client(url, { params });
}

function getManager(userId, query = {}) {
  const url = `v1/users/${userId}/parents`;
  return client(url, { params: query });
}

function getManagerV2(placementId, query = {}) {
  const url = `v1/placements/${placementId}/parent`;
  return client(url, { params: query });
}

function getSubordinate(userId, query = {}) {
  const url = `v1/users/${userId}/children`;
  return client(url, { params: query });
}

function getSubordinateV2(userId, query = {}) {
  const url = `v1/users/${userId}/direct_reports`;
  return client(url, { params: query });
}

function updateConfigurations(userId, placementId, body) {
  const url = `v1/users/${userId}/placements/${placementId}`;
  return client(url, { method: 'PATCH', body: body });
}

function getScore(query, userId) {
  const url = `v1/users/${userId}/score`;
  return client(url, { params: query });
}

function getRecognition(query, userId) {
  const url = `v1/users/${userId}/skills/merged_strengths`;
  return client(url, { params: query });
}

function getSkills(query, userId) {
  const url = `/v1/users/${userId}/skills`;
  return client(url, { params: query });
}

function getObjectivesCount(query, userId) {
  const url = `v1/users/${userId}/objectives/count`;
  return client(url, { params: query });
}

function getAlignmentsCount(query, userId) {
  const url = `v1/users/${userId}/objectives/count/parentage`;
  return client(url, { params: query });
}

function getSkillValueWeakness(query, userId, type) {
  const url = `v1/users/${userId}/${type}/weakness`;
  return client(url, { params: query });
}

function getSkillValueStrength(query, userId, type) {
  const url = `v1/users/${userId}/${type}/merged_strength`;
  return client(url, { params: query });
}

function getDetailStrength(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/merged_strength/${behaviorId}`;
  return client(url, { params: query });
}

function getBehaviorLists(userId, behaviorType, query) {
  const url = `v1/users/${userId}/behaviors/${behaviorType}`;
  return client(url, { params: query });
}

function getBehaviorCycles(
  userId,
  behaviorId,
  query,
  shouldShowErrorToast = true
) {
  const url = `v1/users/${userId}/behaviors/${behaviorId}/cycles`;
  return client(url, { params: query, shouldShowErrorToast });
}

function getBehaviorCycleExpectedLevel(userId, behaviorId, cycleId, query) {
  const url = `v1/users/${userId}/behaviors/${behaviorId}/cycles/${cycleId}/expected_level`;
  return client(url, { params: query });
}

function getBehaviorReviewAnswers(userId, behaviorId, cycleId, query) {
  const url = `v1/users/${userId}/behaviors/${behaviorId}/cycles/${cycleId}/reviews`;
  return client(url, { params: query });
}

function getCountStrength(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/merged_strength/${behaviorId}/count`;
  return client(url, { params: query });
}

function getCountWeakness(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/weakness/${behaviorId}/count`;
  return client(url, { params: query });
}

function getDetailWeakness(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/weakness/${behaviorId}`;
  return client(url, { params: query });
}

function getValueSource(query, type, valueId, userId) {
  const url = `v1/values_scoring/${type}/values/${valueId}/user/${userId}`;
  return client(url, { params: query });
}

function updateWorkloadStatus(data, userId) {
  const url = `v1/users/${userId}`;
  return client(url, { method: 'PATCH', body: data });
}

function getSkillCulture(query, skillId) {
  const url = `v1/culture/skills/${skillId}`;
  return client(url, { params: query });
}
function getSkillPerformance(query, userId, source) {
  const url = `v1/users/${userId}/${source}`;
  return client(url, { params: query });
}

function setPosition(query, userId) {
  const url = `v1/users/${userId}/position`;
  return client(url, { method: 'PUT', body: query });
}

function updateUser(body, userId) {
  const url = `v1/users/${userId}`;
  return client(url, { method: 'PATCH', body: body });
}

//user profile phase 3
function getListCycle(userId, query) {
  const url = `v1/users/${userId}/formal_review/cycles`;
  return client(url, { params: query });
}

function getScoreAspect(placementId, cycleId, scoreAspectId, query) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/score_aspects/${scoreAspectId}`;
  return client(url, { params: query });
}

function getQualitativeAspect(placementId, cycleId, type, query) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/qualitative_aspects/${type}`;
  return client(url, { params: query });
}

function getRightSidebarOverallGoalTask(
  placementId,
  cycleId,
  trackConfigId,
  query
) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/track_configs/${trackConfigId}/objectives`;
  return client(url, { params: query });
}

function getRightSidebarOverallCustom(
  cycleId,
  trackConfigId,
  reviewAspectId,
  query
) {
  const url = `v1/formal_reviews/cycles/${cycleId}/review_aspects_scorings/track_configs/${trackConfigId}/review_aspects/${reviewAspectId}/objectives`;
  return client(url, { params: query });
}

function getScoreAspectAnswer(
  placementId,
  cycleId,
  scoreAspectId,
  objectId,
  query
) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/score_aspects/${scoreAspectId}/answers/${objectId}`;
  return client(url, { params: query });
}

function getQualitativeAspectAnswer(placementId, cycleId, type, objectId) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/qualitative_aspects/${type}/answers/${objectId}`;
  return client(url);
}

function getAdditionalQuestionAnswer(
  placementId,
  cycleId,
  type,
  sectionId,
  query
) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/aspects/${type}/additional_questions/${sectionId}`;
  return client(url, { params: query });
}

function getValueScoringMark(placementId, cycleId, scoreAspectId, query) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/score_aspects/${scoreAspectId}/scoring_marks`;
  return client(url, { params: query });
}

function getAnswerGoalScoringOverall(placementId, cycleId) {
  const url = `v1/placements/${placementId}/formal_review/cycles/${cycleId}/goals_scoring/mechanisms/overall_score/answers`;
  return client(url);
}

export {
  getUser,
  getUserCulture,
  getManager,
  getSubordinate,
  getScore,
  getRecognition,
  getSkills,
  getObjectivesCount,
  getAlignmentsCount,
  getSkillValueWeakness,
  getSkillValueStrength,
  getValueSource,
  getDetailStrength,
  getDetailWeakness,
  getBehaviorLists,
  getBehaviorCycles,
  getBehaviorCycleExpectedLevel,
  getBehaviorReviewAnswers,
  updateWorkloadStatus,
  getSkillCulture,
  getSkillPerformance,
  setPosition,
  updateUser,
  getCountStrength,
  getCountWeakness,
  updateConfigurations,
  getManagerV2,
  getSubordinateV2,

  //userProfile 3
  getListCycle,
  getQualitativeAspect,
  getScoreAspect,
  getScoreAspectAnswer,
  getQualitativeAspectAnswer,
  getAdditionalQuestionAnswer,
  getValueScoringMark,
  getRightSidebarOverallGoalTask,
  getRightSidebarOverallCustom,
  getAnswerGoalScoringOverall
};

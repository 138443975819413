"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildErrorData = exports.handleSentry = exports.progressClientMiddleware = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const Sentry = __importStar(require("@sentry/browser"));
const caseConverter_1 = require("utils/caseConverter");
const socket_1 = require("utils/socket");
const progressClientMiddleware = ({ queryFn = () => null, queryFnCallback, socketChannelParams = [], socketCallback }) => {
    const socketListener = new socket_1.SocketListener();
    return socketListener.register({
        name: socket_1.SocketChannels.backgroundJobProgress(socketChannelParams),
        callback: (data) => {
            if (!data || !socketCallback)
                return;
            socketCallback(data, socketListener);
        },
        callbackConnected: async () => {
            const result = await queryFn();
            queryFnCallback && queryFnCallback(result, socketListener);
        }
    });
};
exports.progressClientMiddleware = progressClientMiddleware;
class NetworkError extends Error {
    constructor(name, message) {
        super(message);
        this.name = name;
        this.stack = new Error().stack;
    }
}
const handleSentry = ({ error, endpoint, config, method, raw }) => {
    if (error.name == 'ERR_CANCELED')
        return;
    Sentry.withScope(function (scope) {
        const getUserData = localStorage.getItem('userData');
        if (getUserData) {
            const userData = JSON.parse(getUserData);
            scope.setUser({ email: userData.email });
            scope.setTag('organization', userData.organizationName);
        }
        scope.setTag('pathname', location.pathname);
        scope.setTag('endpoint', endpoint);
        scope.setTag('type', `${error?.code ? error?.code : ''} ${error.message}`);
        scope.setExtras({
            config: config,
            responseData: error?.response
        });
        scope.setExtras({
            raw: JSON.stringify(raw)
        });
        scope.setFingerprint([method, error?.code.toString(), error?.message]);
        Sentry.captureException(new NetworkError(error?.name || error.code.toString(), error?.message));
    });
};
exports.handleSentry = handleSentry;
const buildErrorData = (e) => {
    let error = e?.response?.data?.error
        ? e?.response?.data?.error
        : e?.response?.data?.errors;
    error = (0, caseConverter_1.toCamelCase)(error);
    error = {
        name: e.code || 'ERR',
        message: error?.message || 'Something Went Wrong',
        code: error?.code || e.code,
        response: e.response,
        retryAfter: error?.retryAfter
    };
    if (e?.code == 'ERR_NETWORK') {
        error.message = 'Network Error';
        error.response = {
            data: {
                response: e?.request?.response,
                responseText: e?.request?.responseText,
                responseType: e?.request?.responseType,
                responseUrl: e?.request?.responseUrl,
                responseXML: e?.request?.responseXML
            }
        };
    }
    return error;
};
exports.buildErrorData = buildErrorData;

import React, { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import Inspect from 'inspx';
import 'scss/main';

import { setInterceptor } from 'client/Axios';
import { useUser } from 'context/UserContext';
import useNetworkError from 'hooks/useNetworkError';
import { getENV } from 'utils/HelperUtils';
import env from 'utils/env';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import UnderMaintenance from 'pages/UnderMaintenance';

import DevTools from './components/dev-tools/DevTools';
import LoadingSplashScreen from './components/shared/LoadingSplashScreen';
import { ToastProvider } from './context/ToastContext';

const AuthenticatedApp = lazyWithRetry(
  () => import(/* webpackChunkName: "AuthenticatedApp" */ './AuthenticatedApp'),
  'authApp'
);

const UnAuthenticatedApp = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "UnAuthenticatedApp" */ './UnAuthenticatedApp'),
  'UnauthApp'
);

const ToastPortal = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ToastPortal" */ 'components/design-system/toast-portal/ToastPortal'
    ),
  'toastPortal'
);
const TourProvider = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "TourContext" */ 'context/TourContext').then(
      (module) => ({ default: module.TourProvider })
    ),
  'TourProvider'
);

Sentry.init({
  environment: process.env.NODE_ENV,
  release: env.SENTRY_RELEASE,
  dsn: env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  ignoreErrors: [
    // Ignore Certain Error
    // Please provide a reason why it should be ignorded

    // Probably Related to Grammarly, Nothing we can do about it
    "Identifier 'originalPrompt' has already been declared",

    // When user misspelled their email when signing in/ signing up
    'User not found',

    // User access non existent post by manipulating URL
    'Culture post not found',

    // Error when input incorrect organization identifier,
    // not worthy to be log
    'Organization not found',

    // Error because of throttling (login)
    // not worthy to be log
    'Too many request, try again later.',

    // Error because user input invalid old password when trying to reset password
    // not worthy to be log
    'Invalid Old Password',

    // According to https://stackoverflow.com/a/50387233
    // We can safely ignored this errors
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',

    // Not yet supported mobile features
    "Cannot read property 'getReadModeConfig' of undefined",
    "Cannot read property 'getReadModeExtract' of undefined",
    "Cannot read property 'getReadModeRender' of undefined",

    // Error due to blank responses and user not submitting reviews until the review cycle ends
    'Assignment already out of date'
  ],
  tracesSampler: (samplingContext) => {
    if (
      samplingContext.location.pathname.includes('/reviews/') ||
      samplingContext.location.pathname.includes('/appraisals')
    ) {
      // Higher chance to log formal review errors
      return 0.7;
    }

    return 0.3;
  },
  beforeSend: sentryBeforeSend
});

function sentryBeforeSend(event) {
  const expected403 = [/users\/\d+\/score/, /v3\/objectives\/\d+/];

  const expected404 = [/culture\/password\/verify_registration/];

  let is_expected_403,
    is_expected_404 = false;
  const errCode = event?.fingerprint?.[1];

  switch (errCode) {
    case 403:
      is_expected_403 = expected403.some((matcher) =>
        event?.tags?.endpoint.match(matcher)
      );
      break;
    case 404:
      is_expected_404 = expected404.some((matcher) =>
        event?.tags?.endpoint.match(matcher)
      );
      break;
    default:
      break;
  }

  if (
    is_expected_403 ||
    is_expected_404 ||
    location.host === 'localhost:5000'
  ) {
    // Escaping expected "Request not Allowed" errors and errors sentry from localhost
    return null;
  } else if (
    event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename ===
    `<anonymous>`
  ) {
    // ignore error from anonymous files
    return null;
  }
  return event;
}

const App = () => {
  const data = useUser();
  const [ready, setReady] = useState(false);
  const toastRef = useRef();
  const { globalHandler } = useNetworkError();
  const isOnUnderMaintenance = getENV('ENABLE_MAINTENANCE_MODE') === '1';

  useEffect(() => {
    setInterceptor(globalHandler);
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ready ? (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      {process.env.NODE_ENV !== 'production' && !window.Cypress && <DevTools />}
      <TourProvider>
        <Inspect disabled={process.env.NODE_ENV === 'production'}>
          {isOnUnderMaintenance ? (
            <UnderMaintenance />
          ) : (
            !data?.isLoading &&
            (data?.user ? <AuthenticatedApp /> : <UnAuthenticatedApp />)
          )}
        </Inspect>
        <ToastPortal ref={toastRef} />
      </TourProvider>
    </React.Suspense>
  ) : (
    <></>
  );
};

const WrappedApp = (props) => {
  return (
    <ToastProvider>
      <App {...props} />
    </ToastProvider>
  );
};

export default WrappedApp;

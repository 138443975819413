export const colorsHex = {
  '#5417CF': 'v',
  '#DB2777': 'p',
  '#E11D48': 'c',
  '#D97706': 'a',
  '#EA580C': 'o',
  '#65A30D': 'l',
  '#059669': 'e',
  '#0D9488': 't',
  '#0891B2': 'cy',
  '#0284C7': 'lb',
  '#4F46E5': 'i',
  '#C026D3': 'f'
};

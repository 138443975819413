import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import * as Sentry from '@sentry/browser';

import { loginUser, loginUserWithToken, logoutUser } from 'client/AuthClient';
import { fetchCultureLocale, fetchObjectiveLocale } from 'client/LocaleClient';
import { useGetUserManager } from 'hooks/api/useUserManager';
import { colorsHex } from 'utils/ColorUtils';
import {
  getDomainFromUrl,
  getENV,
  getHue,
  isValidUrl,
  setMetaLinkTag
} from 'utils/HelperUtils';
import { userAgentUtils } from 'utils/UserAgentUtils';

import { setUserProperties, trackEvent } from 'src/utils/AnalyticUtils';

import {
  initializeFirebase,
  initializeFirebaseCulture,
  unSubcribeFirebase
} from '../utils/FirebaseUtils';

//CREATE CONTEXT
const AuthContext = React.createContext();

// GET QUERY
const platform = userAgentUtils();

const setThemeColor = (mainColor) => {
  mainColor = mainColor && colorsHex[mainColor] ? mainColor : '#5417CF';
  const root = document.documentElement;
  const colorVariant = [100, 200, 300, 400, 500, 600, 700, 800, 900];
  colorVariant.forEach((color) => {
    root.style.setProperty(
      `--base-${color}`,
      `var(--${colorsHex[mainColor]}-${color})`
    );
  });
  const hueColor = getHue(mainColor);
  root.style.setProperty('--base-3008', `hsla(${hueColor}, 98%, 88%, 0.08)`);
  root.style.setProperty('--base-30016', `hsla(${hueColor}, 98%, 88%, 0.16)`);
  root.style.setProperty('--base-30024', `hsla(${hueColor}, 98%, 88%, 0.24)`);
  root.style.setProperty(
    '--base-30024-g',
    `linear-gradient(0deg, hsla(${hueColor}, 98%, 88%, 0.24), hsla(${hueColor}, 98%, 88%, 0.24)), #FFFFFF`
  );
  root.style.setProperty(
    '--base-button-menu-g',
    `linear-gradient(90deg, hsla(${hueColor}, 98%, 88%, 0) 0%, hsla(${hueColor}, 98%, 88%, 0.24) 25px), linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, #FFF 25px)` // 1st layer --base-30024 with transparent gradient 30px, 2nd layer white color with 30px transparent gradient
  );
};

const validateSite = (data, currentApp) => {
  if (
    (process.env.NODE_ENV === 'production' ||
      (process.env.NODE_ENV === 'staging' &&
        !location.host.includes('netlify'))) &&
    platform !== 'iOS' &&
    platform !== 'Android' &&
    platform !== 'unknown'
  ) {
    const clientDomain = getDomainFromUrl(window.location.hostname);

    //redirect based on response backend
    if (data?.organization?.redirect) {
      const redirectDomain = getDomainFromUrl(data?.organization?.redirect);
      if (
        clientDomain === redirectDomain &&
        window.location.hostname !== data?.organization?.redirect
      ) {
        window.location.replace(
          isValidUrl(data?.organization?.redirect)
            ? data?.organization?.redirect
            : `${location.protocol}//${data?.organization?.redirect}${location.pathname}`
        );
      }
    } else if (currentApp) {
      //if currentApp culture, then the user will be redirected to cultureHost
      //if currentApp performance, then user will be redirected to performanceHost
      const hostUrl =
        currentApp === 'culture'
          ? data?.organization?.cultureHost
          : data?.organization?.performanceHost;
      const hostDomain = getDomainFromUrl(hostUrl);
      if (clientDomain === hostDomain && window.location.hostname !== hostUrl) {
        window.location.replace(
          `${location.protocol}//${hostUrl}${location.pathname}`
        );
      }
    } else {
      // if currentApp null, then check user role
      const hostUrl = data?.user?.role
        ? data?.organization?.performanceHost
        : data?.user?.cultureRole
        ? data?.organization?.cultureHost
        : null;
      const hostDomain = getDomainFromUrl(hostUrl);
      if (
        hostUrl &&
        clientDomain === hostDomain &&
        window.location.hostname !== hostUrl
      ) {
        window.location.replace(
          `${location.protocol}//${hostUrl}${location.pathname}`
        );
      }
    }
  }

  if (location.pathname == '/reviews') {
    window.location.replace('/appraisals');
  }
};

const configureSite = ({ data, isSSOLogin }) => {
  const currentApp = data?.organization?.currentApp;
  const isUserCultureOnly =
    currentApp === 'culture' ||
    (!currentApp && data?.user?.cultureRole && !data?.user?.role);
  const assets =
    currentApp || !isUserCultureOnly
      ? data?.config?.assets
      : data?.config?.cultureAssets;
  // 0. validate site
  validateSite(data, currentApp);

  const appName = isUserCultureOnly
    ? data.organization.cultureAppName
    : data.organization.appName;
  // 1. set window name
  window.name = currentApp;
  // 2. set meta tag
  const currentLogo = isUserCultureOnly
    ? data?.organization?.cultureLogoUrl
    : data?.organization?.logoUrl;
  if (data?.config) setMetaLinkTag(currentLogo, assets, appName);
  // 3. set previous url
  if (data?.organization?.redirect) {
    if (
      !location.href?.includes(data?.organization?.redirect) ||
      !location.href?.includes('.happy5.co')
    ) {
      window.location.replace(`https://${data?.organization?.redirect}`);
    }
  }

  if (location.pathname == '/reviews') {
    window.location.replace('/appraisals');
  }
  // 4. set logo url
  localStorage.setItem('logoUrl', data.organization.logoUrl);
  // 5. remove previous locale & bearer
  isSSOLogin && localStorage.removeItem('bearer');
  localStorage &&
    Object.keys(localStorage)
      .filter((x) => x.startsWith('OL_') || x.startsWith('CL_'))
      .forEach((x) => localStorage.removeItem(x));
  localStorage.removeItem('tokenInvalid');

  // 7. set theme color
  setThemeColor(assets?.mainColor);
  // 8. ask and set notification
  if (
    data?.user &&
    'Notification' in window &&
    Notification.permission !== 'denied' &&
    !window.Cypress
  ) {
    if (isUserCultureOnly) {
      initializeFirebaseCulture(data?.token);
    } else {
      initializeFirebase(data?.token);
    }
  }
  // 9. set API host
  const storeApiHost = (url) => {
    if (url) localStorage.setItem('apiHost', url);
  };
  const withHttps = (url) => url?.replace?.(/^(?!http)(.*)/, 'https://$1');

  if (currentApp === 'culture') {
    storeApiHost(withHttps(data?.organization?.cultureApiHost));
  } else if (currentApp === 'performance') {
    storeApiHost(withHttps(data?.organization?.performanceApiHost));
  }
  //10. add login type
  localStorage.setItem('loginType', data.organization.loginType);
  //11. set amplitude info identifier
  if (data?.user?.id) {
    setUserProperties({
      email: data?.user?.email,
      teamName: data?.organization?.identifier
    });
    trackEvent({
      event: 'login',
      env: currentApp
    });
  }

  //12. set sentry scope
  Sentry.configureScope((scope) => {
    scope.setUser({ email: data?.user?.email });
    scope.setTag('organization', data?.organization?.name);
  });
};

//PROVIDER
function AuthProvider(props) {
  const token = new URLSearchParams(location.search).get('token');
  const ssoToken = new URLSearchParams(location.search).get(
    'sso_service_ticket'
  );
  const isSSOLogin = location.pathname === '/' && (token || ssoToken);
  const isRichtextPage = location.pathname == '/richtext';

  const urlAuth = isSSOLogin ? `v1/auth?token=${token || ssoToken}` : 'v1/auth';
  let { data, isError, isLoading, refetch } = useQuery(urlAuth, {
    suspense: false,
    useErrorBoundary: false,
    refetchOnMount: false,
    retry: false,
    enabled: !isRichtextPage,
    onSuccess: (data) => configureSite(data, isSSOLogin),
    onError: () => setMetaLinkTag(null, null, 'Happy5 Performance')
  });

  if (data) {
    data = data.data;
  }

  if (isError) {
    isLoading = setThemeColor();
  }

  const { data: customData } = useQuery('v1/custom_auth', {
    useErrorBoundary: false,
    enabled: !!window.Cypress && !isRichtextPage
  });
  if (customData) {
    data = customData.data;
  }

  const {
    data: localePerfData,
    isSuccess: isLocalePerfSuccess,
    isLoading: isLoadingPerf,
    refetch: refetchObjectiveLocale
  } = useQuery(
    'objectiveLocale',
    () => fetchObjectiveLocale(data?.organization?.identifier),
    { enabled: !!data?.organization?.identifier }
  );

  const {
    data: localeCultureData,
    isSuccess: isLocaleCultureSuccess,
    isLoading: isLoadingCulture,
    refetch: refetchCultureLocale
  } = useQuery(
    'cultureLocale',
    () => fetchCultureLocale(data?.organization?.identifier),
    { enabled: !!data?.organization?.identifier }
  );

  const { data: managers, refetch: refetchManagers } = useGetUserManager(
    data?.user?.placementId,
    {
      enabled: !!data?.user?.placementId,
      refetchOnMount: false
    }
  );

  data = {
    user: data?.user,
    organization: data?.organization,
    config: data?.config,
    manager: data?.user?.role && data?.user?.placementId ? managers?.data : [],
    appType: data?.organization?.currentApp,
    host: data?.organization?.performanceHost,
    cultureHost: data?.organization?.cultureHost,
    isLoading: isLoading || isLoadingCulture || isLoadingPerf
  };

  const refetchAllInfoUser = async (callback) => {
    await refetch();
    !!data?.organization?.identifier && refetchObjectiveLocale();
    !!data?.organization?.identifier && refetchCultureLocale();
    !!data?.user?.placementId && refetchManagers();
    callback && callback();
  };

  const loginWithToken = async (form, organization) => {
    const authorizationMethod = getENV('AUTHORIZATION_METHOD');

    const { data, error, isSuccess } = await loginUserWithToken(
      form,
      organization
    );
    if (data) {
      const provisionToken = data.provisionToken;
      const userData = {
        email: data.user.email,
        organizationName: data.organization.name
      };

      window.localStorage.setItem('userData', JSON.stringify(userData));
      window.localStorage.provisionToken = provisionToken;

      if (authorizationMethod === 'bearer') {
        window.localStorage.bearer = data.token;
      }

      const prevUrl = localStorage.getItem('prevUrlRedirect');
      localStorage.removeItem('prevUrlRedirect');
      location.href = prevUrl ? prevUrl : '/';
    }
    return { error, isSuccess };
  };

  const login = async (form) => {
    const { data, error } = await loginUser(form);
    if (data) {
      if (data.token && getENV('AUTHORIZATION_METHOD') === 'bearer') {
        localStorage.setItem('bearer', data.token);
      } else {
        const userData = {
          email: data.user.email,
          organizationName: data.organization.name
        };

        window.localStorage.setItem('userData', JSON.stringify(userData));
        const prevUrl = localStorage.getItem('prevUrlRedirect');
        localStorage.removeItem('prevUrlRedirect');
        location.href = prevUrl ? prevUrl : '/';
      }
      location.href = '/';
    } else {
      return { error };
    }
  };

  const logout = async () => {
    try {
      if (
        !window.Cypress &&
        'Notification' in window &&
        Notification.permission !== 'denied'
      )
        await unSubcribeFirebase();
    } catch (e) {
      //
    }

    logoutSite();
  };

  const logoutSite = async () => {
    const { data, error } = await logoutUser();

    if (data) {
      const deviceId = localStorage.deviceId;
      const provisionToken = localStorage.provisionToken;
      const loginType = localStorage.getItem('loginType');
      localStorage.clear();

      if (data?.logoutUrl && loginType !== 'google') {
        localStorage.deviceId = deviceId;
        localStorage.provisionToken = provisionToken;
        window.open('/', '_blank');
        window.location = data.logoutUrl;
      } else {
        window.location = '/';
      }
    } else if (error?.code == 401) {
      window.location = '/';
    }
  };

  useEffect(() => {
    if (!data) {
      localStorage.clear();
    } else {
      localStorage.setItem(
        'appType',
        JSON.stringify(data?.organization?.currentApp || null)
      );
    }

    if (isLocalePerfSuccess) {
      const locale = localePerfData.data;
      locale &&
        Object.keys(locale).forEach((key) => {
          localStorage.setItem('OL_' + key, locale[key]);
        });
    }

    if (isLocaleCultureSuccess) {
      const locale = localeCultureData.data;
      locale &&
        Object.keys(locale).forEach((key) => {
          localStorage.setItem('CL_' + key, locale[key]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocalePerfSuccess, isLocaleCultureSuccess, data]);

  return (
    <AuthContext.Provider
      value={{
        data,
        login,
        logout,
        loginWithToken,
        refetch,
        refetchAllInfoUser
      }}
      {...props}
    />
  );
}

//MUTATION
function useAuth() {
  const context = React.useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
